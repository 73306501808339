import React from 'react';


import { Typography } from '@mui/material';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import UserList from './UserList';

const users = [
  { id: 1, username: 'user1', email: 'user1@example.com' },
  { id: 2, username: 'user2', email: 'user2@example.com' },
  // Add more user data as needed
];

const BlogNewsroom = () => {
  

  return (
    <Main className='11111111' colorInvert={true}>
      {/* <Hero /> */}
      <Container sx={{paddingY:3}}>
        <div className='33333333' style={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{paddingBottom:'2rem'}}>
            User Admin
          </Typography>
          <UserList users={users} />
        </div>
      </Container>
     
    </Main>
  );
};

export default BlogNewsroom;
