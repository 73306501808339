import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Headline = () => {
  return (
    <Box>
     
      <Typography variant="h2" align={'center'} fontWeight={700} gutterBottom>
        Your Digital Wallet
      </Typography>
     
    </Box>
  );
};

export default Headline;
