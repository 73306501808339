import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Alert as Alert2,
  Button,
  ButtonGroup,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Alert,
} from "reactstrap";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  TableCell,
} from "@mui/material";
import moment from 'moment';
import _ from "lodash";
import { visuallyHidden } from "@mui/utils";
import { UserContext, api, constants, filterHelpers, helpers } from "../../../utils";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vCard from "vcf";
import { FilterableScreen } from "../../../components";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

// Function to format date
function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "firstName", numeric: false, disablePadding: true, label: "First Name" },
  { id: "lastName", numeric: false, disablePadding: true, label: "Last Name" },
  { id: "company", numeric: false, disablePadding: true, label: "Company" },
  { id: "email", numeric: false, disablePadding: true, label: "Email" },
  { id: "mobilePhoneNumber", numeric: true, disablePadding: false, label: "Phone" },
  { id: "dateAdded", numeric: true, disablePadding: false, label: "Date Added" },
  { id: "profileNumber", numeric: true, disablePadding: false, label: "Employee Name" },
  { id: "Download", numeric: false, disablePadding: false, label: "Download" },
];

const handleDownload = async (leadData) => {
  try {
    const vcard = new vCard();
    vcard.add("fn", `${leadData.firstName} ${leadData.lastName}`);
    vcard.add("tel", leadData.mobilePhoneNumber, { type: ["cell"] });
    vcard.add("email", leadData.email);
    vcard.add("org", leadData.company);
    const vCardData = vcard.toString();
    const encodedVCardData = encodeURIComponent(vCardData);
    window.location.href = `data:text/vcard;charset=utf-8,${encodedVCardData}`;
  } catch (error) {
    console.error("Error:", error);
  }
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className="tableHead"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </th>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, onSearchChange } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
          Leads
        </Typography>
      )}
      <Input
        type="text"
        placeholder="Search by name"
        onChange={onSearchChange}
        style={{ marginLeft: "auto", marginRight: "1rem" }}
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const [filters, setFilters] = useState([{ filterName: "ActiveOnly", value: true, filteredDisplay: "Active Only" }]);
  const [paginatedList, setPaginatedList] = useState({ list: [], totalPages: 0, totalCount: 0 });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstName");
  const [selected, setSelected] = useState([]);
  const [pageNumber, setPageNumber] = useState(0); // pageNumber starts from 0
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [profileNumber, setProfileNumber] = useState(null); // Initially null to indicate all profiles
  const [leadsOverTime, setLeadsOverTime] = useState([]); // For leads over time graph
  const [timeRange, setTimeRange] = useState("day"); // Default time range
  
  
const handleTimeRangeChange = (event) => {
  setTimeRange(event.target.value);
};


  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    fetchPaginatedData();
    fetchAllLeads(); // Fetch all leads for the graph
  }, [filters, pageNumber, rowsPerPage, searchQuery, order, orderBy, profileNumber]); // Include profileNumber in dependencies

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPageNumber(0); // Reset to the first page on search
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0); // Reset to first page when rows per page is changed
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const formatLeadsOverTime = (leads) => {
    const leadsByDate = {};

    leads.forEach((lead) => {
      const date = formatDate(lead.createdAt); // Assuming createdAt is the date field
      if (!leadsByDate[date]) {
        leadsByDate[date] = 0;
      }
      leadsByDate[date]++;
    });

    return Object.keys(leadsByDate).map((date) => ({
      date,
      count: leadsByDate[date],
    }));
  };

  const filterLeadsByTimeRange = (leads) => {
    const filteredData = _.groupBy(leads, (lead) => {
      switch (timeRange) {
        case "week":
          return moment(lead.dateAdded).startOf('week').format('YYYY-MM-DD');
        case "month":
          return moment(lead.dateAdded).startOf('month').format('YYYY-MM');
        case "year":
          return moment(lead.dateAdded).startOf('year').format('YYYY');
        default:
          return moment(lead.dateAdded).format('YYYY-MM-DD');  // Default is by day
      }
    });

    const formattedLeads = Object.keys(filteredData).map((key) => ({
      date: key,
      count: filteredData[key].length,
    }));

    setLeadsOverTime(formattedLeads);  // Update the state with filtered data
  };

  const fetchAllLeads = () => {
    api.post("UserAdmin/GetAllEmployeeLeads")
      .then((response) => {
        const leadsData = formatLeadsOverTime(response.data); 
        filterLeadsByTimeRange(leadsData); // Filter leads based on time range
      })
      .catch((error) => {
        console.error("Error fetching all leads:", error);
      });
  };

  const fetchPaginatedData = () => {
    const payload = {
      sortField: orderBy || "defaultSortField",
      sortDirection: order || "ASC",
      pageNumber: pageNumber + 1, // Convert to 1-based for backend
      maxResults: rowsPerPage,
      searchQuery: searchQuery,
      rowsPerPage: rowsPerPage,
      userId: user.id,
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      mobilePhoneNumber: "",
    };

    api
      .post("UserAdmin/GetPaginatedEmployeeLeads", payload)
      .then((response) => {
        setPaginatedList({
          list: response.data.list,
          totalPages: response.data.totalPages,
          pageNumber: response.data.pageNumber,
        });
     const leadsData = formatLeadsOverTime(response.data.list); 
      setLeadsOverTime(leadsData); })
      
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const FullPageWrapper = styled("div")(() => ({
    minHeight: "100vh", // Full height of the viewport
    // backgroundColor: "#006d71", // Blue background color (same as screenshot)
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    padding: "2rem", // Add padding for spacing
  }));

  const ContentWrapper = styled(Paper)(() => ({
    padding: "2rem", // Add padding for content inside the Paper
    width: "100%",
    maxWidth: "1200px", // Limit max width for readability
  }));

  return (
    <FullPageWrapper>
      <ContentWrapper>
        {/* First Paper for the chart */}
        <Paper elevation={3} sx={{ padding: "2em", marginBottom: "2em" }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6">Leads Over Time</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={leadsOverTime}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Paper>

        {/* Second Paper for the table */}
        <Paper elevation={3} sx={{ padding: "2em" }}>
          <Toolbar>
            <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
              Employee Leads
            </Typography>
            <Input
              type="text"
              placeholder="Search by name"
              onChange={handleSearchChange}
              style={{ marginLeft: "auto", marginRight: "1rem" }}
            />
          </Toolbar>
          <TableContainer>
            <Table>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {paginatedList.list.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.company}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.mobilePhoneNumber}</TableCell>
                    <TableCell>{row.dateAdded}</TableCell>
                    <TableCell>{`${row.profileFirstName} ${row.profileLastName}`}</TableCell>
                    <TableCell>
                      <Button>Download</Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={paginatedList.totalPages * rowsPerPage} // Total number of records
            page={pageNumber}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() =>
              `Page ${paginatedList.pageNumber} of ${paginatedList.totalPages}`
            }
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </ContentWrapper>
    </FullPageWrapper>
  );
  };