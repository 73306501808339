import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState, useContext, useEffect, Fragment } from "react";
import { api, helpers, UserContext, storage } from '../../utils';
import _ from "lodash";
import { Container, Tabs, Tab, Typography, Box } from '@mui/material';
import CompanyUserList from './CompanyUserList';
import ManagerCard from './ManagerCard';
import Main from '../../layouts/Main';
import CompanyTheme from '../../components/CompanyTheme';
import CompanyThemeEdit from './CompanyThemeEdit/CompanyThemeEdit';
import { Page } from '../../components';
import CompanyLeads from '../CompanyLeadList/CompanyLeads';
import CompanyLeadList from '../CompanyLeadList/CompanyLeadList';


const AdminDashboard = () => {
  const userCtx = useContext(UserContext);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
     <Main style={{ width: "100vw" }}>
    <div style={{ backgroundColor: "#026670", width: "100%" }}>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          padding: "20px 0",
        }}
      >
        <div style={{ marginBottom: "20px" }}> {/* Optional margin for spacing */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="admin tabs"
            style={{ width: "100%" }} 
          >
            <Tab label="Users" />
            {/* <Tab label="Managers" /> */}
            <Tab label="Company Profile" />
            <Tab label="Company Leads" />
          </Tabs>
        </div>
        <div style={{ width: "100%" }}> {/* Ensuring the table takes full container width */}
          {tabValue === 0 && <CompanyUserList />}
          {/* {tabValue === 1 && <ManagerCard />} */}
          {tabValue === 1 && <CompanyTheme />}
          {tabValue === 2 && <CompanyLeadList />}
        </div>
      </div>
    </div>
  </Main>
  );
};

export default AdminDashboard;
