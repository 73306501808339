/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.primary.light,
  borderWidth: 2,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.text.main,
  color: theme.palette.text.primary,
  transition: '.7s',
}));

const mock = [
  {
    title: 'Feature 1',
    icon: (
      <svg
        height={48}
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#FE6D73"
      >
        <path
          fillRule="evenodd"
          d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    title: 'Feature 2',
    icon: (
      <svg
        height={48}
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#FE6D73"
      >
        <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
      </svg>
    ),
  },
  {
    title: 'Feature 3',
    icon: (
      <svg
        height={48}
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#FE6D73"
      >
        <path
          fillRule="evenodd"
          d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    title: 'Feature 4',
    icon: (
      <svg
        height={48}
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#FE6D73"
      >
        <path
          fillRule="evenodd"
          d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    title: 'Feature 5',
    icon: (
      <svg
        height={48}
        width={48}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="#FE6D73"
      >
        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
      </svg>
    ),
  },
  // {
  //   title: 'Feature 6',
  //   icon: (
  //     <svg
  //       height={48}
  //       width={48}
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //     >
  //       <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
  //     </svg>
  //   ),
  // },
];

const Categories = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            color:'text.primary',
            fontWeight: 700,
          }}
        >
          Features Built To Help You Grow
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.secondary'}
          data-aos={'fade-up'}
        >
          We have tailored our product to deliver everything you could need and more
          <br />
          With constant new additions
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={4} justifyContent="center">
          {mock.map((item, i) => (
            <Grid item xs={6} md={2} key={i} display="flex" justifyContent="center">
              <Box
                display={'flex'}
                flexDirection="column"
                alignItems="center"
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: `0px 4px 20px ${item.icon.props.fill}`, // Use the icon's fill color for the shadow
                  },
                }}
              >
                <StyledCard
                  data-aos={'fade-up'}
                  data-aos-delay={i * 100}
                  data-aos-offset={100}
                  data-aos-duration={600}
                  sx={{
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Default shadow
                    transition: 'box-shadow .2s ease-in-out',
                    '&:hover': {
                      boxShadow: `0px 4px 10px ${item.icon.props.fill}`, // Match shadow color to icon color
                    },
                  }}
                >
                  <Box
                    position={'relative'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Box width={75} height={75} />
                    <Box
                      sx={{
                        color: 'secondary.dark',
                        position: 'absolute',
                        bottom: 0,
                      }}
                    >
                      {item.icon}
                    </Box>
                  </Box>
                  <Typography
                    variant={'subtitle1'}
                    align={'center'}
                    sx={{ fontWeight: 500, marginTop: 2 }}
                  >
                    {item.title}
                  </Typography>
                </StyledCard>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Categories;