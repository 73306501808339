import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import valid from 'card-validator';
import Box from '@mui/material/Box';

import Page from '../components/Page';
import Main from '../../../layouts/Main';
import CompanyQrEdit from '../../../components/CompanyQrEdit';


const CompanyQRCode = () => {
    return (
    <Main>
      <Page>
        <Box>
          <CompanyQrEdit/>
         
        </Box>
      </Page>
    </Main>
  );
};

export default CompanyQRCode;
