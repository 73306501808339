import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import valid from 'card-validator';
import Box from '@mui/material/Box';

import Page from '../components/Page';
import Main from '../../../layouts/Main';
import QrEdit from '../../../components/QrEdit';


const AccountQRCode = () => {
    return (
    <Main>
      <Page>
        <Box>
          <QrEdit/>
         
        </Box>
      </Page>
    </Main>
  );
};

export default AccountQRCode;
