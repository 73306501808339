
const pages = {
  landings: [
    {
      title: "Data",
      href: "/data",
    },
    {
      title: "Home",
      href: "/",
    },
    {
      title: "E-commerce",
      href: "/e-commerce",
    },
    {
      title: "Product",
      href: "/product",
    },
  ],
  company: [
    {
      title: "Customer Stories",
      href: "/customers",
    },
    {
      title: "About: Startup",
      href: "/about-side-cover",
    },
    {
      title: "Pricing",
      href: "/pricing",
    },
    {
      title: "Terms",
      href: "/company-terms",
    },

    {
      title: "Contact: Minimal",
      href: "/contact-page-cover",
    },
  ],
  account: [
    {
      title: "General Settings",
      href: "/account/general",
    },
    {
      title: "Security Settings",
      href: "/account/security",
    },
    {
      title: "Apps",
      href: "/account/apps",
    },
    {
      title: "Billing Options",
      href: "/account/theme",
    },
    {
      title: "Billing Options",
      href: "/account/theme",
    },
    {
      title: "Company Profile Theme",
      href: "/account/company/theme",
    },
    {
      title: "Change Profile Photo",
      href: "/change-profile-photo",
    },
    // {
    //   title: "Admin User View",
    //   href: "/account-admin-user",
    // },
    // {
    //   title: "Sign up: Cover",
    //   href: "/signup-cover",
    // },
    // {
    //   title: "Sign in: Cover",
    //   href: "/signin-cover",
    // },
    {
      title: "Password Reset",
      href: "/password-reset-cover",
    },
  ],
  secondary: [
    {
      title: "FAQ",
      href: "/faq",
    },
    {
      title: "Error 404: Cover",
      href: "/not-found-cover",
    },
  ],
  blog: [
    {
      title: "Wallet",
      href: "/wallet",
    },
    {
      title: "Public Profile",
      href: "/profile/1",
      //CHANGE THIS
    },
    {
      title: "Admin Test",
      href: "/blog-newsroom",
    },
    {
      title: "User Profile",
      href: "/userprofile",
    },
  ],
  portfolio: [
    {
      title: "Basic",
      href: "/portfolio-page",
    },
  ],
};

export default pages;
