/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import { default as QrEdit } from "./QrEdit";
import { api, helpers, UserContext, constants } from "../utils";
import _, { isNil } from "lodash";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import UserApps from "./UserApps";
import {
  Snackbar,
  Alert,
  Box,
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { text } from "@fortawesome/fontawesome-svg-core";
import ColorPickerComponent from "./ColorPickerComponent";

const Square = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "15em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(3),
  display: "inline-block", // Make squares display inline
}));
const Rectangle = styled(Paper)(({ theme }) => ({
  width: "20em",
  height: "4em",
  position: "relative",
  overflow: "hidden",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "inline-block", // Make squares display inline
}));

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};
const Color1 = styled("div")(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
}));

const Color2 = styled("div")(() => ({
  position: "relative",
  width: "95%",
  height: "2rem",
}));

const calculateLast7Days = () => {
  const today = new Date();
  const last7Days = new Date(today);
  last7Days.setDate(today.getDate() - 7);

  return {
    startDate: last7Days.toISOString().slice(0, 10),
    endDate: today.toISOString().slice(0, 10),
  };
};

const UserThemeStats = ({}) => {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const { startDate, endDate } = calculateLast7Days();
  const [currentStartDate, setCurrentStartDate] = useState(startDate);
  const [currentEndDate, setCurrentEndDate] = useState(endDate);
  const { id } = useParams();
  const [userApps, setUserApps] = useState([]);
  const [profileNumber, setProfileNumber] = useState(1);
  const [userId, setUserId] = useState(0);
  const [profileList, setProfileList] = useState([]);
  const [userProfileId, setUserProfileId] = useState(5);
  const [profile, setProfile] = useState();
  const [contactCode, setContactCode] = useState("");
  const [message, setMessage] = useState(null);
  const [useWhiteText, setUseWhiteText] = useState(true);
  const navigate = useNavigate();
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const [customApps, setCustomApps] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [userClickList, setUserClickList] = useState([]);
  const [profileVisits, setProfileVisits] = useState({});

  function getUserAppsPayload() {
    const allApps = [
      ...businessApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
        userProfileId: userProfileId,
      })),
      ...paymentApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
        userProfileId: userProfileId,
      })),
      ...socialApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
        userProfileId: userProfileId,
      })),
      ...customApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        customAppName: app.customAppName,
        linkSufix: app.linkSufix,
        userId: app.userId,
        userProfileId: userProfileId,
      })),
    ];
    setUserApps(allApps);
  }

  const isPremiumUser =
    userCtx.currentUser.subscriptionId > 0 
    &&
    new Date(userCtx.currentUser.subscriptionEndDate) >= new Date();
    
    console.log(userCtx.currentUser.subscriptionId, "subId")
    console.log(userCtx.currentUser.subscriptionEndDate, "endDate")

  const handleDateChange = (event) => {
    if (!isPremiumUser) return; // Only allow changes for premium users

    if (event.target.name === "startDate") {
      setCurrentStartDate(event.target.value);
    } else if (event.target.name === "endDate") {
      setCurrentEndDate(event.target.value);
    }
  };

  const handleColorChange = (setter) => (value) => {
    if (typeof value === "string") {
      setter(value);
    } else {
      setter(value.target.value);
    }
  };

  useEffect(() => {
    const fetchProfileColors = async () => {
      const response = await api.fetch(`profile/GetProfileColors/${user.id}/${1}`);
      if (response.data) {
        setUserProfileId(response.data.id); // Update userProfileId with the correct value
      }
    };
  
    fetchProfileColors();
  }, [profileNumber]);

  const fetchProductLink = async () => {
    try {
      console.log("UserID before sending:", userId);
      const response = await api.post(
        "userAdmin/generate-link",
        { UserId: user.Id },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Product Link:", response);
      // Redirect to this link
      window.location.href = response.data;
    } catch (error) {
      console.error("Error generating product link:", error);
    }
  };

  const showMessage = (text, type) => {
    setMessage({ text, flavor: type });
    setOpenSnackbar(true); // Open the Snackbar whenever a new message is set
  };

  function assignUserId() {
    setUserId(user.id);
  }

  const getAllUserProfiles = () =>
    api.post(`profile/ProfileList/${user.id}`).then((r) => {
      if (r.data) {
        setProfileList(r.data);
      }
    });

  const handleProfileChange = (event) => {
    setProfileNumber(event.target.value);
    // Optionally, trigger additional actions when the profile changes
  };

  
  useEffect(() => {
    assignUserId();
    getAllUserProfiles();
  }, [profileNumber]);

    useEffect(() => {
        if (userProfileId) {
            getUserProfileApps();
            getUserAppsPayload();
            getUserAppClicks();
            getUserProfileVisits();
        }
    }, [userProfileId]); 

  useEffect(() => {
    // updateUserApps(userApps)
  }, [userApps]);

  const getUserAppClicks = async () => {
    try {
      const payload = {
        startDate: startDate,
        endDate: endDate,
      };
      const response = await api.post(
        `profile/GetUserLinkClicks/${userProfileId}`, // Make sure your URL is correct
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data); // Log to see the structure
      if (Array.isArray(response.data)) {
        setUserClickList(response.data);
      } else {
        setUserClickList([]);
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
      setUserClickList([]);
    }
  };

  const getUserProfileVisits = async () => {
    try {
      const payload = {
        startDate: startDate,
        endDate: endDate,
      };
      const response = await api.post(
        `profile/GetUserProfileVisits/${userProfileId}`,
        payload
      );
      if (response.data) {
        setProfileVisits(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch user profile visits", error);
    }
  };

  const getUserProfileApps = async () => {
    try {
      const response = await api.post(
        `profile/CurrentUserProfileAppList/${userProfileId}`
      );
      if (response.data) {
        // Separate apps based on appGroupId
        const businessApps = response.data.filter(
          (item) => item.app.appGroupId === 1
        );
        const paymentApps = response.data.filter(
          (item) => item.app.appGroupId === 2
        );
        const socialApps = response.data.filter(
          (item) => item.app.appGroupId === 3
        );
        const customApps = response.data.filter(
          (item) => item.app.appGroupId === 4
        );
        setBusinessApps(businessApps);
        setPaymentApps(paymentApps);
        setSocialApps(socialApps);
        setCustomApps(customApps);
        getUserAppsPayload();
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
  };
 

  function getUserAppsPayload() {
    const allApps = [
      ...businessApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        customAppName: "",
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...paymentApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        customAppName: "",
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...socialApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        customAppName: "",
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...customApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        customAppName: app.customAppName,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
    ];
    return allApps;
  }








  

  return (
    <div>
      <div
        className="LOOK AT ME"
        style={{
          padding: 3,
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between", // Changed from center to space-between
          alignItems: "center",
        }}
      >
        {" "}
        <div style={{ flexGrow: 1 }}>
          <div>
            <div>
                          <Row>
                              <Col
                                  style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      padding: "0px",
                                  }}
                                  xs={6}
                                  md={4}
                              >
                                  {isPremiumUser ? (
                                      <TextField
                                          label="Start Date"
                                          type="date"
                                          value={currentStartDate}
                                          onChange={handleDateChange}
                                          name="startDate"
                                          InputLabelProps={{ shrink: true }}
                                          sx={{
                                              margin: 1,
                                              '& .MuiInputBase-input': {
                                                  color: 'white', // Input text color
                                              },
                                              '& .MuiInputLabel-root': {
                                                  color: 'white', // Label color
                                              },
                                              '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                      borderColor: 'white', // Border color
                                                  },
                                              },
                                          }}
                                      />
                                  ) : (
                                      <TextField
                                          label="Start Date"
                                          type="date"
                                          value={currentStartDate}
                                          onChange={handleDateChange}
                                          name="startDate"
                                          InputLabelProps={{ shrink: true }}
                                          sx={{
                                              margin: 1,
                                              '& .MuiInputBase-input': {
                                                  color: 'white', // Input text color
                                              },
                                              '& .MuiInputLabel-root': {
                                                  color: 'white', // Label color
                                              },
                                              '& .MuiOutlinedInput-root': {
                                                  '& fieldset': {
                                                      borderColor: 'white', // Border color
                                                  },
                                              },
                                          }}
                                          disabled
                                      />
                                  )}
                              </Col>
                              <Col
                                  style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      padding: "0px",
                                  }}
                                  xs={6}
                                  md={4}
                              >
                                  <TextField
                                      label="End Date"
                                      type="date"
                                      value={currentEndDate}
                                      onChange={handleDateChange}
                                      name="endDate"
                                      InputLabelProps={{ shrink: true }}
                                      InputProps={{ readOnly: !isPremiumUser }} // Make it read-only for non-premium users
                                      sx={{
                                          margin: 1,
                                          '& .MuiInputBase-input': {
                                              color: 'white', // Input text color
                                          },
                                          '& .MuiInputLabel-root': {
                                              color: 'white', // Label color
                                          },
                                          '& .MuiOutlinedInput-root': {
                                              '& fieldset': {
                                                  borderColor: 'white', // Border color
                                              },
                                          },
                                      }}
                                  />
                              </Col>
                              <Col
                                  style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      padding: "0px",
                                      alignItems: "center",
                                  }}
                                  xs={11}
                                  md={4}
                              >
                                  <Box
                                      display="flex"
                                      flexDirection={{ xs: 'column', sm: 'row' }}
                                      alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                                  >
                                      <Button
                                          component={'a'}
                                          variant="contained"
                                          color="customColor"
                                          size="large"
                                          onClick={getUserAppClicks}
                                      >
                                          Learn More
                                      </Button>
                                  </Box>
                              </Col>
                          </Row>
              {/* Add Date Pickers for Start and End Dates */}
              <Row></Row>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Typography  variant="h6" style={{ color:'white', fontWeight: 600 }}>
              Profile Visits: {profileVisits.count}
            </Typography>
          </div>
          <div>
            <div style={{ paddingBottom: "4rem" }}>
                          <Typography variant="h6" style={{ color: 'white' }} gutterBottom>
                App Clicks: {userClickList.count}
              </Typography>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Clicks Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userClickList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell align="right">{item.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
       
        
        
         
        </div>{" "}
      </div>
      
    </div>
  );
};
const styles = {
  inputWrapper: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputBox: {
    flexGrow: 10,
    margin: 5,
    width: 100,
  },
};
export default UserThemeStats;
