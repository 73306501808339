/* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Options,
  FileExtension
} from 'qr-code-styling';
import { TextField, Select, MenuItem, InputLabel, FormControl, Input, InputAdornment, Slider } from '@mui/material';
// import Extension from 'qr-code-styling';
import { NexxLogoBlack } from '../../assets/images';

const dotStyleList = [
  { label: 'Square', value: 'square' },
  { label: 'Dots', value: 'dots' },
  { label: 'Rounded', value: 'rounded' },
  { label: 'Extra Rounded', value: 'extra-rounded' },
  { label: 'Classy', value: 'classy' },
  { label: 'Classy Rounded', value: 'classy-rounded' },
];
const cornerSquareStyleList = [
  { label: 'None', value: '' },
  { label: 'Square', value: 'square' },
  { label: 'Dot', value: 'dot' },
  { label: 'Extra Rounded', value: 'extra-rounded' },
];
const cornerDotStyleList = [
  { label: 'None', value: '' },
  { label: 'Square', value: 'square' },
  { label: 'Dot', value: 'dot' },
];
const errorCorrectionLevels = [
  { label: 'Low', value: 'L' },
  { label: 'Medium', value: 'M' },
  { label: 'High', value: 'Q' },
  { label: 'Ultra', value: 'H' },
];

export default function QrF() {
  const [url, setUrl] = useState(
    ''
  );
  const [qrcWidth, setQrcWidth] = useState('250');
  const [qrcHeight, setQrcHeight] = useState('250');
  const [dotOptionsType, setDotOptionsType] = useState('rounded');
  const [dotOptionsColor, setDotOptionsColor] = useState('#FF0000');
  const [cornersSquareOptionsType, setCornersSquareOptionsType] = useState('dot');
  const [cornersSquareColor, setcornersSquareColor] = useState('#FF0000');
  const [cornersDotType, setCornersDotOptionsType] = useState('dot');
  const [cornersDotColor, setCornersDotOptionsColor] = useState('#FF0000');
  const [errorCorrectionLevel, setErrorCorrectionLevel] = useState('M');
  const [logoSize, setLogoSize] = useState('0.25');

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    data: url,
    margin: 1,
    qrOptions: {
      typeNumber: '0',
      mode: 'Byte',
      errorCorrectionLevel: errorCorrectionLevel
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: logoSize,
      margin: 4
    },
    dotsOptions: {
      type: dotOptionsType,
      color: dotOptionsColor
    },
    backgroundOptions: {
      color: '#ffffff'
    },
    image:
      NexxLogoBlack,
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      },
      gradient: {
        linear: true,
        radial: false,
        color1: '#6a1a4c',
        color2: '#6a1a4c',
        rotation: '0'
      }
    },
    cornersSquareOptions: {
      type: cornersSquareOptionsType,
      color: cornersSquareColor
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0'
      }
    },
    cornersDotOptions: {
      type: cornersDotType,
      color: cornersDotColor
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      },
      gradient: {
        linear: true,
        radial: false,
        color1: '#000000',
        color2: '#000000',
        rotation: '0'
      }
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false
      },
      gradient: {
        linear: true,
        radial: false,
        color1: '#ffffff',
        color2: '#ffffff',
        rotation: '0'
      }
    }
  });

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, [qrCode]);

  useEffect(() => {
    qrCode.update({
      dotOptions: {
        type: dotOptionsType,
        color: dotOptionsColor
      }
    });
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url
    });
  }, [url]);
  
  useEffect(() => {
    qrCode.update({
      dotOptions: {
        type: dotOptionsType,
        color: dotOptionsColor,
        
      },
    });
  }, [qrCode]);
  
  useEffect(() => {
    qrCode.update({
      cornersSquareOptions: {
        type: cornersSquareOptionsType,
        color: cornersSquareColor,
      },
    });
  }, [cornersSquareOptionsType, cornersSquareColor]);

  useEffect(() => {
    qrCode.update({
      cornersDotOptions: {
        type: cornersDotType,
        color: cornersDotColor,
      },
    });
  }, [cornersDotType, cornersDotColor]);

  useEffect(() => {
    qrCode.update({
      qrOptions: {
        errorCorrectionLevel: errorCorrectionLevel,
      },
    });
  }, [errorCorrectionLevel]);

  useEffect(() => {
    if (qrcWidth > 150) {
      qrCode.update({
        width: qrcWidth
      });
    }
  }, [qrcWidth]);

  useEffect(() => {
    if (qrcHeight > 150) {
      qrCode.update({
        height: qrcHeight
      });
    }
  }, [qrcHeight]);

  useEffect(() => {
    if (logoSize > 0) {
      qrCode.update({
        imageOptions: { imageSize: logoSize }
      });
    }
  }, [logoSize]);

  const onUrlChange = (event) => {
    event.preventDefault();
    setUrl(event.target.value);
  };
  const onDotTypeChange = (event) => {
    event.preventDefault();
    setDotOptionsType(event.target.value);
  };
  const onDotColorChange = (event) => {
    event.preventDefault();
    setDotOptionsColor(event.target.value);
  };
  const onCornerSquareTypeChange = (event) => {
    event.preventDefault();
    setCornersSquareOptionsType(event.target.value);
  };
  const onCornerSquareColorChange = (event) => {
    event.preventDefault();
    setcornersSquareColor(event.target.value);
  };
  const onCornerDotTypeChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsType(event.target.value);
  };
  const onCornerDotColorChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsColor(event.target.value);
  };
  const onErrorCorrectionLevelChange = (event) => {
    event.preventDefault();
    setErrorCorrectionLevel(event.target.value);
  };

  const onWidthChange = (event) => {
    event.preventDefault();
    setQrcWidth(event.target.value);
  };

  const onHeightChange = (event) => {
    event.preventDefault();
    setQrcHeight(event.target.value);
  };

  const onLogoChange = (event) => {
    event.preventDefault();
    setLogoSize(event.target.value);
  };

  return (
    <div className='App'>
      <div ref={ref} />
      <br />
    </div>
  );
}

const styles = {
  inputWrapper: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  inputBox: {
    flexGrow: 1,
    marginRight: 20
  }
};