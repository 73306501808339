/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { UserContext, api, helpers } from '../../../../utils'; // Adjust the import path as needed
import { Box, Grid, Typography, TextField, Button, Link, Select } from '@mui/material';
import NumberFormat from 'react-number-format';
import _ from 'lodash';


const Form = ({id}) => {
  // const { id } = useParams();
  {console.log(id, "IDDDDDDDDDD?")}
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  // Define initial user state based on whether user is new or existing
  const initialUserState = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    company: '',
    title: '',
    mobilePhoneNumber: '',
    timezone: '',
  };

  const [user, setUser] = useState(initialUserState);
  const [message, setMessage] = useState(null);
  const [timezoneList, setTimezoneList] = useState([]);
  const [showCompanyForm, setShowCompanyForm] = useState(false);
  const isNewUser = !id || parseInt(id, 10) < 1;

  const getUser = () => api.fetch(`Public/UserActivate/${id}`).then(r => ({
    user: r.data
  }));

  

  useEffect(() => {
    console.log(id, "USER ID IN THE FORM");
    let apiCalls = [api.getTimezones()];
    if (!isNewUser) {
      apiCalls.push(getUser());
    }
    Promise.all(apiCalls).then(arrayResults => {
      let aggResults = {};
      _.each(arrayResults, x => Object.assign(aggResults, x));
      setTimezoneList(aggResults.timezones || []);
      setUser(aggResults.user || initialUserState); // Fallback to initial state if no user data
    }).catch(error => setMessage({flavor: "alert-danger", text: "There was an error loading user data"}));
  }, [id, isNewUser]);
 
  function onChange(fieldName, fieldValue) {
    let newUser = Object.assign({}, user);
    newUser[fieldName] = fieldValue;
    setUser(newUser);
    console.log(fieldValue)
  }
 
 
 
  function validate() {
    let warnings = [];
    if (!user.firstName || _.trim(user.firstName).length < 2) {
      warnings.push('Please provide a valid first name.');
    }
    if (!user.lastName || _.trim(user.lastName).length < 2) {
      warnings.push('Please provide a valid last name.');
    }
    if ((!user.password || user.password.length < 8) && user.id === 0) {
      warnings.push('Please provide a password of at least 8 characters.');
    }
    if (!user.email || !helpers.emailIsValid(_.trim(user.email))) {
      warnings.push('Please provide a valid email.');
    }
    // if (!user.roles || user.roles.length === 0) {
    //   warnings.push('Please select at least 1 role for the user.')
    // }
    return warnings;
  }
 
  function onSave() {
    window.scroll(0, 0);
    const warnings = validate();
    if (warnings.length) {
      setMessage({
        text: warnings.join(' '),
        flavor: 'alert-danger'
      });
      return;
    } else {
      setMessage(null);
    }
   
    let payload = Object.assign({}, user);
    payload.firstName = _.trim(payload.firstName);
    payload.lastName = _.trim(payload.lastName);
    payload.email = _.trim(payload.email);
    payload.password = _.trim(payload.password);
    payload.company = _.trim(payload.company);
    payload.title = _.trim(payload.title);
    payload.username = payload.email; // username is always set to email
    if (isNewUser) {
      delete payload.id;
    }
    api.post('public/RegisterNewUser', payload).then(response => {
      if (response.data.success) {
        setUser({...user, id: response.data.message.id})
        navigate("/login");
      } else {
        setMessage({text: response.data.message, flavor: "alert-danger"});
      }
    }).catch(helpers.catchHandler);
  }
 


  const initialValues = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };

  function onChange(fieldName, fieldValue) {
    let newUser = Object.assign({}, user);
    newUser[fieldName] = fieldValue;
    setUser(newUser);
  }

  const onSubmit = (values) => {
    onSave();
    console.log(values);
  };
  const handleCompanyFormSubmit = (e) => {
    // Handle company form submission here
    e.preventDefault();
    // Add logic to handle company form submission
  };

  const handleSignUp = (e) => {
    e.preventDefault();
 
    // Check if the form is valid
    const isFormValid = formik.isValid;
 
    if (isFormValid) {
      if (showCompanyForm) {
        // Handle signup after company form submission
        // Add logic to submit the entire form
      } else {
        // Show company form section
        setShowCompanyForm(true);
      }
    } else {
      // Handle case when the form is not valid (e.g., display an error message)
      console.log('Form is not valid');
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Signup
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Create an account
        </Typography>
        <Typography color="text.secondary">
          Fill out the form to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
        {!showCompanyForm ? (
        <>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              onChange={(event) => onChange(event.target.name, event.target.value)}
              value={user.email ?  user.email : ""}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={'password'}
              type={'password'}
              fullWidth
              value={user.password}
              onChange={(event) => onChange(event.target.name, event.target.value)}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="First name *"
              variant="outlined"
              name={'firstName'}
              value={user.firstName || ""}
              fullWidth
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
             
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={'lastName'}
              fullWidth
              onChange={(event) => onChange(event.target.name, event.target.value)}
              value={user.lastName || ""}
             
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Company
            </Typography>
            <TextField
              label="Company"
              variant="outlined"
              name={'company'}
              fullWidth
              onChange={(event) => onChange(event.target.name, event.target.value)}
              value={user.company || ""}
             
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Title*"
              variant="outlined"
              name={'title'}
              fullWidth
              onChange={(event) => onChange(event.target.name, event.target.value)}
              value={user.title || ""}
             
            />
          </Grid>
         
          <Grid item xs={12} sm={6}>
  <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
    Enter your Phone Number
  </Typography>
  <NumberFormat
    customInput={TextField}
    label="Phone Number"
    variant="outlined"
    format="(###) ###-####"
    mask="_"
    name="mobilePhoneNumber"
    id="mobilePhoneNumber"
    fullWidth
    value={user.mobilePhoneNumber || ""}
    onValueChange={(values) => {
      const { value } = values;
      onChange("mobilePhoneNumber", value);
    }}
  />
</Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Timezone
            </Typography>
            <Select
                  options={timezoneList}
                  value={_.find(timezoneList, x => x.value === user.timezone)}
                  onChange={(option) => onChange('timezone', option.value)}
                />
          </Grid>
         
         
        </> ) : (
            <>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter Company Code If You Have Been Given One Here
            </Typography>
            <TextField
              label="Company Code"
              variant="outlined"
              name={'company'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your company
            </Typography>
            <TextField
              label="Company *"
              variant="outlined"
              name={'company'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your position
            </Typography>
            <TextField
              label="Position *"
              variant="outlined"
              name={'position'}
              fullWidth
            />
          </Grid>
          </>
          )}
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>
                  Already have an account?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/signin-cover'}
                    underline={'none'}
                  >
                    Login.
                  </Link>
                </Typography>
              </Box>
              <Button onClick={() => handleSignUp} size={'large'} variant={'contained'} type={'submit'}>
                Sign up
              </Button>
            </Box>
            <form
            //  onSubmit={handleSignUp}
             >
          </form>
          </Grid>
         
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'text.secondary'}
              align={'center'}
            >
              By clicking "Sign up" button you agree with our{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/company-terms'}
                underline={'none'}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;