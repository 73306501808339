import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

const mock = [
  {
    title: 'Built For BUSINESSES',
    subtitle:
      'We offer the ability to track and export employee lead generation data. Never worry about your team forgetting crucial documents, send your team prepared to presentations with the capability to send them any documents they may need.',
  },
  {
    title: 'Going Green',
    subtitle:
      'We will plant a tree with every purchase of a NEXX Card. We believe in leaving as little of an impact on the enviornment as possible.',
  },
  {
    title: 'Brand Differentiation Point 3',
    subtitle:
      'Text about the differentiaion point here.',
  },
];

const QuickSearch = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4} display="flex" alignItems="center">
  <Typography fontWeight={700} variant={'h4'} color={'text.primary'}>
    What makes us
  </Typography>
  <Typography fontWeight={700} variant={'h4'} color={'primary.attention'} marginLeft={1}>
    different?
  </Typography>
</Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Box
              component={Card}
              width={1}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              data-aos={'fade-up'}
              data-aos-delay={0}
              data-aos-offset={100}
              data-aos-duration={600}
              bgcolor={'text.primary'}
            >
              <CardContent>
                <Typography
                  variant={'h5'}
                  gutterBottom
                  sx={{ fontWeight: 600, color:'white' }}
                >
                  Built For Businesses
                </Typography>
                <Typography color='white'>
                  We offer the ability to track and export employee lead generation data. Never worry about your team forgetting crucial documents, send your team prepared to presentations with the capability to send them any documents they may need.
                </Typography>
              </CardContent>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Box
              component={Card}
              width={1}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              data-aos={'fade-up'}
              data-aos-delay={100}
              data-aos-offset={100}
              data-aos-duration={600}
              bgcolor={'text.primary'}
            >
              <CardContent>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 600, color:'white' }}
                >
                  Going Green
                </Typography>
                <Typography color='white'>
                  We will plant a tree with every purchase of a NEXX Card. We believe in leaving as little of an impact on the environment as possible.
                </Typography>
              </CardContent>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box
            display={'block'}
            width={1}
            height={1}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Box
              component={Card}
              width={1}
              height={1}
              display={'flex'}
              flexDirection={'column'}
              data-aos={'fade-up'}
              data-aos-delay={200}
              data-aos-offset={100}
              data-aos-duration={600}
              bgcolor={'text.primary'}
            >
              <CardContent>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 600, color:'white' }}
                >
                  Brand Differentiation Point 3
                </Typography>
                <Typography color='white'>
                  Text about the differentiation point here.
                </Typography>
              </CardContent>
              <Box flexGrow={1} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuickSearch;