import React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { jesseSmall } from '../../../../assets/images';
import './Hero.css';
import Container from '../../../.../../../components/Container';
import { Nevin } from '../../../../assets/images';

// const styles = (theme) => ({
//   avatar: {
//     width: 100, // Default width
//     height: 100, // Default height

//     [theme.breakpoints.up('sm')]: {
//       width: 150, // Width on small screens and larger
//       height: 150, // Height on small screens and larger
//     },

//     [theme.breakpoints.up('md')]: {
//       width: 200, // Width on medium screens and larger
//       height: 200, // Height on medium screens and larger
//     },
//   },
// });

const Hero = ({ selectedColor }) => {

  return (
    <Box
      className={selectedColor}
      position={'relative'}
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: -13,
        paddingTop: 10,
        paddingBottom: 5,
      }}
    >
      <Container
        zIndex={3}
        position={'relative'}
        minHeight={{ xs: 300, sm: 300, md: 500 }}
        maxHeight={500}
        display={'flex'}
        alignItems={'flex-center'}
        justifyContent={'center'}
        sx={{ paddingBottom: 13,alignItems:'center' }}
      >
        <Box sx={{alignItems:'center'}}>
          <Avatar
            alt="Circular Picture"
            variant="rounded"
            src={Nevin}
            sx={{ 
              borderRadius:'30px',
              width: { xs: '10em', md: '17em' },
              height: { xs: '10em', md: '17em' }
            }}
            // sx={{
            //   width: '9em',
            //   height: '9em',
            //   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.6)',
            // }}
          />
        </Box>
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        maxHeight={120}
        bottom={0}
        position={'absolute'}
        zIndex={2}
      >
        <path
          className={`${selectedColor}Darkest`}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;