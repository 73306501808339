import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Alert as Alert2,
  Button,
  ButtonGroup,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Alert,
} from "reactstrap";
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  TableCell,
} from "@mui/material";
import _ from "lodash";
import { visuallyHidden } from "@mui/utils";
import { UserContext, api, constants, filterHelpers, helpers } from "../../../utils";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vCard from "vcf";
import { FilterableScreen } from "../../../components";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  
  function formatDate(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  
  const headCells = [
    { id: "firstName", numeric: false, disablePadding: true, label: "First Name" },
    { id: "lastName", numeric: false, disablePadding: true, label: "Last Name" },
    { id: "company", numeric: false, disablePadding: true, label: "Company" },
    { id: "email", numeric: false, disablePadding: true, label: "Email" },
    { id: "mobilePhoneNumber", numeric: true, disablePadding: false, label: "Phone" },
    { id: "dateAdded", numeric: true, disablePadding: false, label: "Date Added" },
    { id: "profileNumber", numeric: true, disablePadding: false, label: "Profile #" },
    { id: "Download", numeric: false, disablePadding: false, label: "Download" },
  ];
  
  function handleDownload(leadData) {
    try {
      const vcard = new vCard();
      vcard.firstName = leadData.firstName;
      vcard.lastName = leadData.lastName;
      vcard.email = leadData.email;
      vcard.workPhone = leadData.mobilePhoneNumber;
      vcard.organization = leadData.company;
      const vCardData = encodeURIComponent(vcard.getFormattedString());
      window.location.href = `data:text/vcard;charset=utf-8,${vCardData}`;
    } catch (error) {
      console.error("Error generating vCard:", error);
    }
  }
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    return array
      .map((el, index) => [el, index])
      .sort((a, b) => {
        const order = comparator(a[0], b[0]);
        return order !== 0 ? order : a[1] - b[1];
      })
      .map((el) => el[0]);
  }
  
  function EnhancedTableHead({ order, orderBy, onRequestSort }) {
    const createSortHandler = (property) => (event) => onRequestSort(event, property);
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
  };
  
  function EnhancedTableToolbar({ numSelected, onSearchChange }) {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{ flex: "1 1 100%" }} variant="h6">
            Leads
          </Typography>
        )}
        <Input
          type="text"
          placeholder="Search by name"
          onChange={onSearchChange}
          sx={{ ml: "auto", mr: "1rem" }}
        />
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSearchChange: PropTypes.func.isRequired,
  };
  
  export default function EnhancedTable() {
    const userCtx = useContext(UserContext);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("firstName");
    const [pageNumber, setPageNumber] = useState(0); // 0-based for Material-UI
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState([{ filterName: "ActiveOnly", value: true }]);
    const [paginatedList, setPaginatedList] = useState({
      list: [],
      pageNumber: 1, // 1-based for backend
      totalPages: 1, // Backend should send the total pages available
    });
    const [selected, setSelected] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [profileNumber, setProfileNumber] = useState(null);
  
    useEffect(() => {
      refreshData();
    }, [filters, pageNumber, rowsPerPage, order, orderBy, searchQuery]);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const handleSearchChange = (event) => {
      const value = event.target.value;
      setFilters((prevFilters) => {
        const newFilters = [...prevFilters];
        const nameFilter = newFilters.find((f) => f.filterName === "Name");
        if (nameFilter) {
          nameFilter.value = value;
        } else {
          newFilters.push({ filterName: "Name", value });
        }
        return newFilters;
      });
      setPageNumber(1); // Reset to the first page on search
    };
  
    const handleChangePage = (event, newPage) => {
      setPageNumber(newPage + 1); // 1-based for backend
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPageNumber(1); // Reset to the first page when changing rows per page
    };
  
    function createPayload() {
      let payload = {
        sortField: orderBy || "defaultSortField",
        sortDirection: order || "ASC",
        pageNumber: pageNumber, // 1-based for backend
        maxResults: rowsPerPage,
        searchQuery: searchQuery,
        userId: userCtx.currentUser.id,
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        mobilePhoneNumber: "",
      };
  
      _.each(filters, (filter) => {
        if (filter.value !== undefined && filter.value !== null) {
          payload[filter.filterName] = filter.value;
        }
      });
  
      if (profileNumber !== null) {
        payload.profileNumber = profileNumber;
      }
  
      return payload;
    }
  
    function refreshData() {
      api
        .post("UserAdmin/GetPaginatedLeads", createPayload())
        .then((response) => {
          setPaginatedList(response.data);
        })
        .catch(helpers.catchHandler);
    }
  
    const handleClick = (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    };
  
    return (
      <Container fluid sx={{ minHeight: "100vh", padding: 0 }}>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <EnhancedTableToolbar numSelected={selected.length} onSearchChange={handleSearchChange} />
          <TableContainer sx={{ flexGrow: 1 }}>
            <Table>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {stableSort(paginatedList.list, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = selected.indexOf(row.id) !== -1;
                  const labelId = `enhanced-table-checkbox-${index}`;
  
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.firstName}
                      </TableCell>
                      <TableCell align="right">{row.lastName}</TableCell>
                      <TableCell align="right">{row.company}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">{row.mobilePhoneNumber}</TableCell>
                      <TableCell align="right">{formatDate(row.createdAt)}</TableCell>
                      <TableCell align="right">{row.profileNumber}</TableCell>
                      <TableCell align="right">
                        <ButtonGroup className="float-right">
                          <Button size="sm" title="Download" onClick={() => handleDownload(row)}>
                            Download
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={paginatedList.totalPages * rowsPerPage} // Estimate total count based on pages
            page={pageNumber - 1} // Convert to 0-based index for frontend
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={() => `Page ${paginatedList.pageNumber} of ${paginatedList.totalPages}`}
          />
        </Paper>
      </Container>
    );
  }