/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  InputAdornment,
  TextField,
  Box,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
} from "@mui/material";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { default as QrEdit } from "./QrEdit";
import { api, helpers, UserContext, constants } from "../utils";
import _, { isNil } from "lodash";

const TABS = {
  DETAILS: "Profile Details",
  PASSWORD: "Credentials",
};

const UserApps = ({
    saveUserProfileAppsCallback,
    updateUserAppsCallback,
    userProfileId, }) => {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const [showAllBusinessApps, setShowAllBusinessApps] = useState(false);
  const [showAllMoneyApps, setShowAllMoneyApps] = useState(false);
  const [showAllSocialApps, setShowAllSocialApps] = useState(false);
  const [showAllCustomApps, setShowAllCustomApps] = useState(false);
  const [businessApps, setBusinessApps] = useState([]);
  const [paymentApps, setPaymentApps] = useState([]);
  const [socialApps, setSocialApps] = useState([]);
  const [customApps, setCustomApps] = useState([]);
  const [allBusinessApps, setAllBusinessApps] = useState([]);
  const [allPaymentApps, setAllPaymentApps] = useState([]);
  const [allSocialApps, setAllSocialApps] = useState([]);
  const [allCustomApps, setAllCustomApps] = useState([]);
  const [commonBusinessAppIds, setCommonBusinessAppIds] = useState([]);
  const [commonCustomAppIds, setCommonCustomAppIds] = useState([]);
  const [commonPaymentAppIds, setCommonPaymentAppIds] = useState([]);
  const [commonSocialAppIds, setCommonSocialAppIds] = useState([]);
  const navigate = useNavigate();
  console.log('userProfileId top of page: ', userProfileId);
  
  const getUserProfileApps = async () => {
    try {
      const response = await api.post(`public/CurrentUserProfileAppList/${userProfileId}`, {});
      if (response.data) {
        // Separate apps based on appGroupId
        const businessApps = response.data.filter(
          (item) => item.app.appGroupId === 1
        );
        const paymentApps = response.data.filter(
          (item) => item.app.appGroupId === 2
        );
        const socialApps = response.data.filter(
          (item) => item.app.appGroupId === 3
        );
        const customApps = response.data.filter(
          (item) => item.app.appGroupId === 4
        );
        setBusinessApps(businessApps);
        setPaymentApps(paymentApps);
        setSocialApps(socialApps);
        setCustomApps(customApps);
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
  };  
  const findCommonBusinessAppIds = (businessApps, allBusinessApps) => {
    const userBusinessAppIds = businessApps.map((item) => item.app.id);
    const allBusinessAppIds = allBusinessApps.map((item) => item.id);
    return userBusinessAppIds.filter((id) => allBusinessAppIds.includes(id));
  };
  const findCommonPaymentAppIds = (paymentApps, allPaymentApps) => {
    const userPaymentAppIds = paymentApps.map((item) => item.app.id);
    const allPaymentAppIds = allPaymentApps.map((item) => item.id);
    return userPaymentAppIds.filter((id) => allPaymentAppIds.includes(id));
  };
  const findCommonSocialAppIds = (socialApps, allSocialApps) => {
    const userSocialAppIds = socialApps.map((item) => item.app.id);
    const allSocialAppIds = allSocialApps.map((item) => item.id);
    return userSocialAppIds.filter((id) => allSocialAppIds.includes(id));
  };
  const findCommonCustomAppIds = (customApps, allCustomApps) => {
    const userCustomAppIds = customApps.map((item) => item.app.id);
    const allCustomAppIds = allCustomApps.map((item) => item.id);
    return userCustomAppIds.filter((id) => allCustomAppIds.includes(id));
  };
  
  

  function getUserAppsPayload () {
    const allApps = [
      ...businessApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...paymentApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...socialApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ...customApps.map((app) => ({
        app: app.app,
        createdByuserId: app.createdByuserId,
        deactivatedAt: app.deactivatedAt,
        deactivatedByUserId: app.deactivatedByUserId,
        id: app.id,
        name: app.name,
        linkSufix: app.linkSufix,
        userId: app.userId,
      })),
      ];
      // updateUserAppsCallback(allApps);
    api
      .post(`Profile/SaveUserProfileApps/${user.id}/${userProfileId}`, allApps)
      .then((response) => {
        if (response.data && response.data.success) {
          const allBusinessApps = response.data.message.filter(
            (item) => item.app.appGroupId === 1
          );
          const allPaymentApps = response.data.message.filter(
            (item) => item.app.appGroupId === 2
          );
          const allSocialApps = response.data.message.filter(
            (item) => item.app.appGroupId === 3
          );
          const allCustomApps = response.data.message.filter(
            (item) => item.app.appGroupId === 4
          );

          setBusinessApps(allBusinessApps);
          setPaymentApps(allPaymentApps);
          setSocialApps(allSocialApps);
          setCustomApps(allCustomApps);
          saveUserProfileAppsCallback();
        } else {
          console.log({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch(helpers.catchHandler);
  }

  const getAllApps = async () => {
    try {
      const response = await api.post("public/AppList", {});
      if (response.data) {
        const allBusinessApps = response.data.filter(
          (item) => item.appGroupId === 1
        );
        const allPaymentApps = response.data.filter(
          (item) => item.appGroupId === 2
        );
        const allSocialApps = response.data.filter(
          (item) => item.appGroupId === 3
        );
        const allCustomApps = response.data.filter(
          (item) => item.appGroupId === 4
        );

        setAllBusinessApps(allBusinessApps);
        setAllPaymentApps(allPaymentApps);
        setAllSocialApps(allSocialApps);
        setAllCustomApps(allSocialApps);
        
        const commonBusinessAppIds = findCommonBusinessAppIds(
          businessApps,
          allBusinessApps
        );
        setCommonBusinessAppIds(commonBusinessAppIds);
        const commonPaymentAppIds = findCommonPaymentAppIds(
          paymentApps,
          allPaymentApps
        );
        setCommonPaymentAppIds(commonPaymentAppIds);
        const commonSocialAppIds = findCommonSocialAppIds(
          socialApps,
          allSocialApps
        );
        setCommonSocialAppIds(commonSocialAppIds);
        const commonCustomAppIds = findCommonCustomAppIds(
          customApps,
          allCustomApps
        );
        setCommonCustomAppIds(commonSocialAppIds);
      }
    } catch (error) {
      console.error("Failed to fetch user apps", error);
    }
   };

   useEffect(() => {
     if(!isNil(userProfileId)) {
       getUserProfileApps();

     }
  }, [userProfileId]);

   
  useEffect(() => {
    getAllApps();
  }, [businessApps, paymentApps, socialApps,customApps]);

  const handleToggleBusinessApp = (selectedApp) => {
    const isBusinessAppInUserApps = businessApps.some(
      (app) => app.app.id === selectedApp.id
    );

    if (isBusinessAppInUserApps) {
      setBusinessApps((prevBusinessApps) =>
        prevBusinessApps.filter((app) => app.app.id !== selectedApp.id)
      );
    } else {
      const formattedApp = {
        app: {
          appGroupId: selectedApp.appGroupId,
          iconUrl: selectedApp.iconUrl,
          linkPrefix: selectedApp.linkPrefix,
          id: selectedApp.id,
          name: selectedApp.name,
        },
        linkSufix: selectedApp.linkSufix,
      };
      setBusinessApps((prevBusinessApps) => [...prevBusinessApps, formattedApp]);
    }
    const isAppInCommonApps = commonBusinessAppIds.some(
      (app) => app.id === selectedApp.id
    );
    if (!isAppInCommonApps) {
      setCommonBusinessAppIds((prevCommonApps) => [
        ...prevCommonApps,
        selectedApp,
      ]);
    }
  };

const handleTogglePaymentApp = (selectedApp) => {
  const isPaymentAppInUserApps = paymentApps.some(
    (app) => app.app.id === selectedApp.id
  );

  if (isPaymentAppInUserApps) {
    setPaymentApps((prevPaymentApps) =>
      prevPaymentApps.filter((app) => app.app.id !== selectedApp.id)
    );
  } else {
    const formattedApp = {
      app: {
        appGroupId: selectedApp.appGroupId,
        iconUrl: selectedApp.iconUrl,
        linkPrefix: selectedApp.linkPrefix,
        id: selectedApp.id,
        name: selectedApp.name,
      },
      linkSufix: selectedApp.linkSufix,
    };
    setPaymentApps((prevPaymentApps) => [...prevPaymentApps, formattedApp]);
  }
  const isAppInCommonApps = commonPaymentAppIds.some(
    (app) => app.id === selectedApp.id
  );
  if (!isAppInCommonApps) {
    setCommonPaymentAppIds((prevCommonApps) => [
      ...prevCommonApps,
      selectedApp,
    ]);
  }
};

const handleToggleSocialApp = (selectedApp) => {
  const isSocialAppInUserApps = socialApps.some(
    (app) => app.app.id === selectedApp.id
  );

  if (isSocialAppInUserApps) {
    setSocialApps((prevSocialApps) =>
      prevSocialApps.filter((app) => app.app.id !== selectedApp.id)
    );
  } else {
    const formattedApp = {
      app: {
        appGroupId: selectedApp.appGroupId,
        iconUrl: selectedApp.iconUrl,
        linkPrefix: selectedApp.linkPrefix,
        id: selectedApp.id,
        name: selectedApp.name,
      },
      linkSufix: selectedApp.linkSufix,
    };
    setSocialApps((prevSocialApps) => [...prevSocialApps, formattedApp]);
  }
  const isAppInCommonApps = commonSocialAppIds.some(
    (app) => app.id === selectedApp.id
  );

  if (!isAppInCommonApps) {
    setCommonSocialAppIds((prevCommonApps) => [
      ...prevCommonApps,
      selectedApp,
    ]);
  }
};
const handleToggleCustomApp = (selectedApp) => {
  const isCustomAppInUserApps = customApps.some(
    (app) => app.app.id === selectedApp.id
  );

  if (isCustomAppInUserApps) {
    setCustomApps((prevCustomApps) =>
      prevCustomApps.filter((app) => app.app.id !== selectedApp.id)
    );
  } else {
    const formattedApp = {
      app: {
        appGroupId: selectedApp.appGroupId,
        iconUrl: selectedApp.iconUrl,
        linkPrefix: selectedApp.linkPrefix,
        id: selectedApp.id,
        name: selectedApp.name,
      },
      linkSufix: selectedApp.linkSufix,
    };
    setCustomApps((prevCustomApps) => [...prevCustomApps, formattedApp]);
  }
  const isAppInCommonApps = commonCustomAppIds.some(
    (app) => app.id === selectedApp.id
  );

  if (!isAppInCommonApps) {
    setCommonCustomAppIds((prevCommonApps) => [
      ...prevCommonApps,
      selectedApp,
    ]);
  }
};

  return (
    <DialogContent className="p-0" sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4 }}
            >
              Business Apps
            </Typography>
            <Button
              onClick={() => setShowAllBusinessApps(!showAllBusinessApps)}
            >
              <EditIcon sx={{ fontSize: "1rem" }} />
            </Button>
          </Box>
          <>
            {showAllBusinessApps ? (
              <Grid className="FirstOne" container spacing={1}>
                {allBusinessApps.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    sm={3}
                    md={3}
                    sx={{ textAlign: "center", marginBottom: "1em" }}
                  >
                    <>
                      <Box
                        component="img"
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          cursor: "pointer",
                          border: commonBusinessAppIds.includes(item.id)
                            ? "2px solid blue"
                            : "none",
                        }}
                        alt="Business App"
                        src={item.iconUrl}
                        onClick={() => handleToggleBusinessApp(item)}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {businessApps.map((selectedApp, index) => (
              <div className="DIALOUGGGEEE" sx={{ width: 1 }} key={index}>
                <>
                  <img
                    src={selectedApp.app.iconUrl}
                    alt="App Icon"
                    style={{ height: "3em", marginRight: "0.5rem" }}
                  />
                  <DebounceInput
                    element={TextField}
                    debounceTimeout={500} 
                    label={selectedApp.app.linkPrefix}
                    sx={{ width: "75%" }}
                    value={selectedApp.linkSufix}
                    onChange={(e) => {
                      setBusinessApps((prevBusinessApps) => {
                        const updatedBusinessApps = prevBusinessApps.map((app) =>
                            app.linkSufix === selectedApp.linkSufix
                              ? { ...app, linkSufix: e.target.value }
                              : app
                        );
                        return updatedBusinessApps;
                      });
                    }}
                  />
                </>
              </div>
            ))}
          </>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4 }}
            >
              Payment Apps
            </Typography>
            <Button onClick={() => setShowAllMoneyApps(!showAllMoneyApps)}>
              <EditIcon sx={{ fontSize: "1rem" }} />
            </Button>
          </Box>
          <>
            {showAllMoneyApps ? (
              <Grid className="SecondOne" container spacing={1}>
                {allPaymentApps.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    sm={3}
                    md={3}
                    sx={{ textAlign: "center", marginBottom: "1em" }}
                  >
                    <>
                      <Box
                        component="img"
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          cursor: "pointer",
                          border: commonPaymentAppIds.includes(item.id)
                            ? "2px solid blue"
                            : "none",
                        }}
                        alt="Money App"
                        src={item.iconUrl}
                        onClick={() => handleTogglePaymentApp(item)}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {paymentApps.map((selectedApp, index) => (
              <div
                className="DIALOUGGGEEE"
                sx={{ width: 1, textAlign: "space-between" }}
                key={index}
              >
                <>
                  <img
                    src={selectedApp.app.iconUrl}
                    alt="App Icon"
                    style={{ height: "3em", marginRight: "0.5rem" }}
                  />
                  <DebounceInput
                    element={TextField}
                    debounceTimeout={500}
                    label={selectedApp.app.linkPrefix}
                    sx={{ width: "75%" }}
                    value={selectedApp.linkSufix}
                    onChange={(e) => {
                      setPaymentApps((prevPaymentApps) => {
                        const updatedPaymentApps = prevPaymentApps.map((app) =>
                          app.linkSufix === selectedApp.linkSufix
                            ? { ...app, linkSufix: e.target.value }
                            : app
                        );
                        return updatedPaymentApps;
                      });
                    }}
                  />
                </>
              </div>
            ))}
          </>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingY: 2,
            }}
          >
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ fontWeight: 700, paddingBottom: 1, paddingRight: 4 }}
            >
              Social Apps
            </Typography>
            <Button onClick={() => setShowAllSocialApps(!showAllSocialApps)}>
              <EditIcon sx={{ fontSize: "1rem" }} />
            </Button>
          </Box>
          <>
            {showAllSocialApps ? (
              <Grid className="SecondOne" container spacing={1}>
                {allSocialApps.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    sm={3}
                    md={3}
                    sx={{ textAlign: "center", marginBottom: "1em" }}
                  >
                    <>
                      <Box
                        component="img"
                        sx={{
                          height: "3rem",
                          width: "3rem",
                          maxHeight: { xs: 233, md: 167 },
                          maxWidth: { xs: 350, md: 250 },
                          cursor: "pointer",
                          border: commonSocialAppIds.includes(item.id)
                            ? "2px solid blue"
                            : "none",
                        }}
                        alt="Money App"
                        src={item.iconUrl}
                        onClick={() => handleToggleSocialApp(item)}
                      />
                    </>
                  </Grid>
                ))}
              </Grid>
            ) : null}
            {socialApps.map((selectedApp, index) => (
              <div
                className="DIALOUGGGEEE"
                sx={{ width: 1, textAlign: "space-between" }}
                key={index}
              >
                <>
                  <img
                    src={selectedApp.app.iconUrl}
                    alt="App Icon"
                    style={{ height: "3em", marginRight: "0.5rem" }}
                  />
                  <DebounceInput
                    element={TextField}
                    debounceTimeout={500}
                    label={selectedApp.app.linkPrefix}
                    sx={{ width: "75%" }}
                    value={selectedApp.linkSufix}
                    onChange={(e) => {
                      setSocialApps((prevSocialApps) => {
                        const updatedSocialApps = prevSocialApps.map((app) =>
                          app.linkSufix === selectedApp.linkSufix
                            ? { ...app, linkSufix: e.target.value }
                            : app
                        );
                        return updatedSocialApps;
                      });
                    }}
                  />
                </>
              </div>
            ))}
              <Button onClick={getUserAppsPayload}>SAVE APPS TO USER</Button>
          </>
        </DialogContent>
  );
}
export default UserApps;