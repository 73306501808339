/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { api, helpers } from "../../../../utils";


const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const Form = () => {
  const submitURL = "Public/ForgotPassword";
  const backToSignInPath = "/login";
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [authData, setAuthData] = useState({ username: state && state.email ? state.email : "" });


  const initialValues = {
    email: '',
  };


  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function validate() {
    let warnings = [];
    if (!authData.username) {
      warnings.push("Username is required");
    }
    return warnings;
  }

  function onChange(fieldName, fieldValue) {
    let newData = Object.assign({}, authData);
    newData[fieldName] = fieldValue;
    setAuthData(newData);
  }

  function onSubmit() {
    const warnings = validate();
    if (warnings.length) {
      setMessage({
        text: warnings.join(" "),
        flavor: "alert-danger",
      });
      return;
    } else {
      setMessage(null);
    }
    api
      .post(submitURL, {
        username: authData.username,
      })
      .then((response) => {
        if (response.data.success) {
          setMessage({
            flavor: "alert-success",
            text: "If we have a user with that address, we've just sent a reset link!",
          });
          setTimeout(() => {
            navigate("/");
          }, 5000);
        } else {
          setMessage({ flavor: "alert-danger", text: response.data.message });
        }
      })
      .catch(helpers.catchHandler);
  }

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Recover account
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Forgot your password?
        </Typography>
        <Typography color="text.secondary">
          Enter your email address below and we'll get you back on track.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Email *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={authData.username}
              onChange={(e) =>
                onChange("username", e.target.value)
              }
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  component={Link}
                  href={'/signin-cover'}
                  fullWidth
                >
                  Back to login
                </Button>
              </Box>
              <Button onClick={onSubmit} size={'large'} variant={'contained'} type={'submit'}>
                Send reset link
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
