import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';

import Page from '../components/Page';
import Main from '../../../layouts/Main';
import UserApps from '../../../components/UserApps';

const Notifications = () => {
  return (
    <Main>
      <Page>
      <UserApps/>
      </Page>
    </Main>
  );
};

export default Notifications;
