/* eslint-disable indent */ /* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Select, MenuItem } from "@mui/material";
import Switch from "@mui/material/Switch";
import { api, helpers, UserContext } from "../utils";
import _, { isNil } from "lodash";
import DebounceInput from "react-debounce-input";
import EditIcon from "@mui/icons-material/Edit";
import NumberFormat from "react-number-format";
import {
    Snackbar,
    Alert,
    Box,
    Typography,
    Grid,
    TextField,
    Button,
} from "@mui/material";

const Square = styled(Paper)(({ theme }) => ({
    width: "20em",
    height: "15em",
    position: "relative",
    overflow: "hidden",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    display: "inline-block",
}));
const Rectangle = styled(Paper)(({ theme }) => ({
    width: "20em",
    height: "4em",
    position: "relative",
    overflow: "hidden",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "inline-block",
}));

const Color1 = styled("div")(() => ({
    position: "relative",
    width: "100%",
    height: "100%",
}));

const Color2 = styled("div")(() => ({
    position: "relative",
    width: "95%",
    height: "2rem",
}));

const calculateLast7Days = () => {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    return {
        startDate: last7Days.toISOString().slice(0, 10),
        endDate: today.toISOString().slice(0, 10),
    };
};

const UserTheme = ({ }) => {
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState(userCtx.currentUser);
    const { startDate, endDate } = calculateLast7Days();
    const [currentStartDate, setCurrentStartDate] = useState(startDate);
    const [currentEndDate, setCurrentEndDate] = useState(endDate);
    const { id } = useParams();
    const [userApps, setUserApps] = useState([]);
    const [profileNumber, setProfileNumber] = useState(1);
    const [userId, setUserId] = useState(userCtx.currentUser.id);
    const [profileList, setProfileList] = useState([]);
    const [userProfileId, setUserProfileId] = useState(5);
    const [primaryColor, setPrimaryColor] = useState("#fff");
    const [secondaryColor, setSecondaryColor] = useState("#444");
    const [highlightColor, setHighlightColor] = useState("#111");
    const [bottomColor, setBottomColor] = useState("#111");
    const [namePanelColor, setNamePanelColor] = useState("#111");
    const [companyPanelColor, setCompanyPanelColor] = useState("#111");
    const [requireContactCode, setRequireContactCode] = useState(false);
    const [requireProfileCode, setRequireProfileCode] = useState(false);
    const [profileCode, setProfileCode] = useState("");
    const [profile, setProfile] = useState();
    const [contactCode, setContactCode] = useState("");
    const [message, setMessage] = useState(null);
    const [useWhiteText, setUseWhiteText] = useState(true);
    const navigate = useNavigate();
    const [showAllBusinessApps, setShowAllBusinessApps] = useState(false);
    const [showAllMoneyApps, setShowAllMoneyApps] = useState(false);
    const [showAllSocialApps, setShowAllSocialApps] = useState(false);
    const [showAllCustomApps, setShowAllCustomApps] = useState(false);
    const [businessApps, setBusinessApps] = useState([]);
    const [paymentApps, setPaymentApps] = useState([]);
    const [socialApps, setSocialApps] = useState([]);
    const [customApps, setCustomApps] = useState([]);
    const [allBusinessApps, setAllBusinessApps] = useState([]);
    const [allPaymentApps, setAllPaymentApps] = useState([]);
    const [allSocialApps, setAllSocialApps] = useState([]);
    const [allCustomApps, setAllCustomApps] = useState([]);
    const [commonBusinessAppIds, setCommonBusinessAppIds] = useState([]);
    const [commonPaymentAppIds, setCommonPaymentAppIds] = useState([]);
    const [commonSocialAppIds, setCommonSocialAppIds] = useState([]);
    const [commonCustomAppIds, setCommonCustomAppIds] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [userClickList, setUserClickList] = useState([]);
    const [profileVisits, setProfileVisits] = useState({});
    const [profileName, setProfileName] = useState("");
    const [profilePhone, setProfilePhone] = useState("");
    const [profileEmail, setProfileEmail] = useState("");
    const [company, setCompany] = useState("");
    const [title, setTitle] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const [userProfileArticles, setUserProfileArticles] = useState([
        {
            id: 1,
            article: {
                title: "Article 1",
                thumbnailUrl: "Thumbnail 2",
                linkUrl: "URL 1",
                description: "Description 2",
            },
            display: true,
        },
        {
            id: 2,
            article: {
                title: "Article 1",
                thumbnailUrl: "Thumbnail 2",
                linkUrl: "URL 1",
                description: "Description 2",
            },
            display: true,
        },
        {
            id: 3,
            article: {
                title: "Article 1",
                thumbnailUrl: "Thumbnail 2",
                linkUrl: "URL 1",
                description: "Description 2",
            },
            display: true,
        },
    ]);

    useEffect(() => {
        const fetchData = async () => {
            await getAllUserProfiles();
            await getProfileColors();
            if (userProfileId) {
                await getUserProfileApps();
            }
        };

        fetchData();
    }, [profileNumber, userProfileId]);

    const isPremiumUser =
        userCtx.currentUser.subscriptionId > 0 &&
        new Date(userCtx.currentUser.subscriptionEndDate) >= new Date();

    console.log(userCtx.currentUser.subscriptionId, "subId");
    console.log(userCtx.currentUser.subscriptionEndDate, "endDate");

    const handleDateChange = (event) => {
        if (!isPremiumUser) return; // Only allow changes for premium users

        if (event.target.name === "startDate") {
            setCurrentStartDate(event.target.value);
        } else if (event.target.name === "endDate") {
            setCurrentEndDate(event.target.value);
        }
    };

    const getUserArticles = async () => {
        try {
            const response = await api.post(
                `profile/CurrentUserArticleList/${user.id}`,
                {}
            );
            if (response.data) {
                // Update state for all articles at once
                setUserProfileArticles(response.data);

                console.log(response.data, "response"); // Verify if you're getting the correct data
                console.log(userProfileArticles, "userArticles"); // Verify if you're getting the correct data
                console.log(userProfileArticles.data.title, "title"); // Verify if you're getting the correct data
            }
        } catch (error) {
            console.error("Failed to fetch user articles", error);
        }
    };

    const handleColorChange = (setter) => (value) => {
        if (typeof value === "string") {
            setter(value);
        } else {
            setter(value.target.value);
        }
    };

    const fetchProductLink = async () => {
        try {
            console.log("UserID before sending:", userId);
            const response = await api.post(
                "userAdmin/generate-link",
                { UserId: user.Id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            console.log("Product Link:", response);
            // Redirect to this link
            window.location.href = response.data;
        } catch (error) {
            console.error("Error generating product link:", error);
        }
    };

    const showMessage = (text, type) => {
        setMessage({ text, flavor: type });
        setOpenSnackbar(true); // Open the Snackbar whenever a new message is set
    };

    const formatPhoneNumber = (phoneNumber) => {
        const formattedPhoneNumber = phoneNumber.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
        return formattedPhoneNumber;
    };

    const handlePhoneNumberChange = (value) => {
        const inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
        const formattedValue = formatPhoneNumber(inputValue);
        setProfilePhone(formattedValue); // Always update the formatted value
        if (inputValue.length === 10 || inputValue.length === 0) {
            setPhoneNumberError(""); // Clear error message if length is valid
        } else {
            setPhoneNumberError("Phone number must be 10 digits.");
        }
    };

    const saveProfileThemeAndApps = () => {
        const allApps = getUserAppsPayload(); // Retrieve all apps with their current data
        let warnings = [];

        // Validate each app to ensure the linkSufix is non-empty after trimming
        allApps.forEach((app) => {
            if (!app.linkSufix || _.trim(app.linkSufix).length === 0) {
                warnings.push(`All active apps must have a link.`);
            }
        });

        if (warnings.length) {
            // If any app fails validation, show an aggregated message and prevent saving
            showMessage(warnings.join(" "), "alert-danger");
            return; // Stop the save operation
        }
        let themePayload = {
            profileName: _.trim(profileName),
            company: _.trim(company),
            title: _.trim(title),
            profileEmail: _.trim(profileEmail),
            profilePhone: _.trim(profilePhone),
            userId: _.trim(user.id),
            primaryColor: _.trim(primaryColor),
            activationCode: "",
            secondaryColor: _.trim(secondaryColor),
            highlightColor: _.trim(highlightColor),
            bottomColor: _.trim(bottomColor),
            requireProfileCode,
            requireContactCode,
            useWhiteText,
            lockedProfileCode: _.trim(profileCode),
            lockedContactCode: _.trim(contactCode),
            namePanelColor: _.trim(namePanelColor),
            companyPanelColor: _.trim(companyPanelColor),
            profileNumber: profileNumber,
            apps: allApps,
            isDefault: isDefault,
        };

        api
            .post(`Profile/SaveProfileTheme/${user.id}/${profileNumber}`, themePayload)
            .then((response) => {
                if (response.data.success) {
                    showMessage("Profile Theme Update Was Successful", "alert-success");
                } else {
                    showMessage(
                        "There was an error saving your profile theme, please refresh the page and try again.",
                        "alert-danger"
                    );
                }
            })
            .catch(helpers.catchHandler);
    };

    const getAllUserProfiles = async () => {
        try {
            const response = await api.post(`profile/ProfileList/${user.id}`);
            if (response.data) {
                setProfileList(response.data);
                    setProfileName(response.data[0].profileName);
                setCompany(response.data[0].company);
                    setTitle(response.data[0].title);
                    setProfileEmail(response.data[0].profileEmail);
                    setProfilePhone(response.data[0].profilePhone);
                
            }
        } catch (error) {
            console.error("Failed to fetch user profiles", error);
        }
    };

    const handleProfileChange = (event) => {
        setProfileNumber(event.target.value);
        // Optionally, trigger additional actions when the profile changes
    };

    const getProfileColors = async () => {
        try {
            const response = await api.fetch(`profile/GetProfileColors/${user.id}/${profileNumber}`);
            if (response.data) {
                setPrimaryColor(response.data.primaryColor);
                setSecondaryColor(response.data.secondaryColor);
                setHighlightColor(response.data.highlightColor);
                setBottomColor(response.data.bottomColor);
                setCompanyPanelColor(response.data.companyPanelColor);
                setNamePanelColor(response.data.namePanelColor);
                setUserProfileId(response.data.id);
                setProfile(response.data);
            }
        } catch (error) {
            console.error("Failed to fetch profile colors", error);
        }
    };

    useEffect(() => {
        getProfileColors();
        getAllUserProfiles();
    }, [profileNumber]);

    const getUserProfileApps = async () => {
        try {
            const response = await api.post(`profile/CurrentUserProfileAppList/${userProfileId}`);
            if (response.data) {
                // Separate apps based on appGroupId
                const businessApps = response.data.filter((item) => item.app.appGroupId === 1);
                const paymentApps = response.data.filter((item) => item.app.appGroupId === 2);
                const socialApps = response.data.filter((item) => item.app.appGroupId === 3);
                const customApps = response.data.filter((item) => item.app.appGroupId === 4);
                setBusinessApps(businessApps);
                setPaymentApps(paymentApps);
                setSocialApps(socialApps);
                setCustomApps(customApps);
                getUserAppsPayload();
            }
        } catch (error) {
            console.error("Failed to fetch user apps", error);
        }
    };

    const findCommonBusinessAppIds = (businessApps, allBusinessApps) => {
        const userBusinessAppIds = businessApps.map((item) => item.app.id);
        const allBusinessAppIds = allBusinessApps.map((item) => item.id);
        return userBusinessAppIds.filter((id) => allBusinessAppIds.includes(id));
    };

    const findCommonPaymentAppIds = (paymentApps, allPaymentApps) => {
        const userPaymentAppIds = paymentApps.map((item) => item.app.id);
        const allPaymentAppIds = allPaymentApps.map((item) => item.id);
        return userPaymentAppIds.filter((id) => allPaymentAppIds.includes(id));
    };

    const findCommonSocialAppIds = (socialApps, allSocialApps) => {
        const userSocialAppIds = socialApps.map((item) => item.app.id);
        const allSocialAppIds = allSocialApps.map((item) => item.id);
        return userSocialAppIds.filter((id) => allSocialAppIds.includes(id));
    };

    const findCommonCustomAppIds = (customApps, allCustomApps) => {
        const userCustomAppIds = customApps.map((item) => item.app.id);
        const allCustomAppIds = allCustomApps.map((item) => item.id);
        return userCustomAppIds.filter((id) => allCustomAppIds.includes(id));
    };

    const getUserAppsPayload = () => {
        const allApps = [
            ...businessApps.map((app) => ({
                app: app.app,
                createdByuserId: app.createdByuserId,
                deactivatedAt: app.deactivatedAt,
                deactivatedByUserId: app.deactivatedByUserId,
                id: app.id,
                customAppName: "",
                name: app.name,
                linkSufix: app.linkSufix,
                userId: app.userId,
            })),
            ...paymentApps.map((app) => ({
                app: app.app,
                createdByuserId: app.createdByuserId,
                deactivatedAt: app.deactivatedAt,
                deactivatedByUserId: app.deactivatedByUserId,
                id: app.id,
                name: app.name,
                customAppName: "",
                linkSufix: app.linkSufix,
                userId: app.userId,
            })),
            ...socialApps.map((app) => ({
                app: app.app,
                createdByuserId: app.createdByuserId,
                deactivatedAt: app.deactivatedAt,
                deactivatedByUserId: app.deactivatedByUserId,
                id: app.id,
                name: app.name,
                customAppName: "",
                linkSufix: app.linkSufix,
                userId: app.userId,
            })),
            ...customApps.map((app) => ({
                app: app.app,
                createdByuserId: app.createdByuserId,
                deactivatedAt: app.deactivatedAt,
                deactivatedByUserId: app.deactivatedByUserId,
                id: app.id,
                customAppName: app.customAppName,
                name: app.name,
                linkSufix: app.linkSufix,
                userId: app.userId,
            })),
        ];
        return allApps;
    };

    const getAllApps = async () => {
        try {
            const response = await api.post("public/AppList", {});
            if (response.data) {
                const allBusinessApps = response.data.filter((item) => item.appGroupId === 1);
                const allPaymentApps = response.data.filter((item) => item.appGroupId === 2);
                const allSocialApps = response.data.filter((item) => item.appGroupId === 3);
                const allCustomApps = response.data.filter((item) => item.appGroupId === 4);

                setAllBusinessApps(allBusinessApps);
                setAllPaymentApps(allPaymentApps);
                setAllSocialApps(allSocialApps);
                setAllCustomApps(allCustomApps);

                const commonBusinessAppIds = findCommonBusinessAppIds(businessApps, allBusinessApps);
                setCommonBusinessAppIds(commonBusinessAppIds);

                const commonPaymentAppIds = findCommonPaymentAppIds(paymentApps, allPaymentApps);
                setCommonPaymentAppIds(commonPaymentAppIds);

                const commonSocialAppIds = findCommonSocialAppIds(socialApps, allSocialApps);
                setCommonSocialAppIds(commonSocialAppIds);
                const commonCustomAppIds = findCommonCustomAppIds(customApps, allCustomApps);
                setCommonCustomAppIds(commonCustomAppIds);
            }
        } catch (error) {
            console.error("Failed to fetch user apps", error);
        }
    };

    useEffect(() => {
        if (userProfileId) {
            getUserProfileApps();
            getUserAppsPayload();
        }
    }, [userProfileId]);

    useEffect(() => {
        getAllApps();
    }, []);

    const handleToggleBusinessApp = (selectedApp) => {
        const isBusinessAppInUserApps = businessApps.some(
            (app) => app.app.id === selectedApp.id
        );

        if (isBusinessAppInUserApps) {
            setBusinessApps((prevBusinessApps) =>
                prevBusinessApps.filter((app) => app.app.id !== selectedApp.id)
            );
        } else {
            const formattedApp = {
                app: {
                    appGroupId: selectedApp.appGroupId,
                    iconUrl: selectedApp.iconUrl,
                    linkPrefix: selectedApp.linkPrefix,
                    id: selectedApp.id,
                    name: selectedApp.name,
                },
                linkSufix: selectedApp.linkSufix,
            };
            setBusinessApps((prevBusinessApps) => [
                ...prevBusinessApps,
                formattedApp,
            ]);
        }

        const isAppInCommonApps = commonBusinessAppIds.some(
            (app) => app.id === selectedApp.id
        );
        if (!isAppInCommonApps) {
            setCommonBusinessAppIds((prevCommonApps) => [
                ...prevCommonApps,
                selectedApp,
            ]);
        }
    };

    const handleTogglePaymentApp = (selectedApp) => {
        const isPaymentAppInUserApps = paymentApps.some(
            (app) => app.app.id === selectedApp.id
        );

        if (isPaymentAppInUserApps) {
            setPaymentApps((prevPaymentApps) =>
                prevPaymentApps.filter((app) => app.app.id !== selectedApp.id)
            );
        } else {
            const formattedApp = {
                app: {
                    appGroupId: selectedApp.appGroupId,
                    iconUrl: selectedApp.iconUrl,
                    linkPrefix: selectedApp.linkPrefix,
                    id: selectedApp.id,
                    name: selectedApp.name,
                },
                linkSufix: selectedApp.linkSufix,
            };
            setPaymentApps((prevPaymentApps) => [...prevPaymentApps, formattedApp]);
        }

        const isAppInCommonApps = commonPaymentAppIds.some(
            (app) => app.id === selectedApp.id
        );
        if (!isAppInCommonApps) {
            setCommonPaymentAppIds((prevCommonApps) => [
                ...prevCommonApps,
                selectedApp,
            ]);
        }
    };

    const handleToggleSocialApp = (selectedApp) => {
        const isSocialAppInUserApps = socialApps.some(
            (app) => app.app.id === selectedApp.id
        );

        if (isSocialAppInUserApps) {
            setSocialApps((prevSocialApps) =>
                prevSocialApps.filter((app) => app.app.id !== selectedApp.id)
            );
        } else {
            const formattedApp = {
                app: {
                    appGroupId: selectedApp.appGroupId,
                    iconUrl: selectedApp.iconUrl,
                    linkPrefix: selectedApp.linkPrefix,
                    id: selectedApp.id,
                    name: selectedApp.name,
                },
                linkSufix: selectedApp.linkSufix,
            };
            setSocialApps((prevSocialApps) => [...prevSocialApps, formattedApp]);
        }

        const isAppInCommonApps = commonSocialAppIds.some(
            (app) => app.id === selectedApp.id
        );
        if (!isAppInCommonApps) {
            setCommonSocialAppIds((prevCommonApps) => [
                ...prevCommonApps,
                selectedApp,
            ]);
        }
    };

    const handleToggleCustomApp = (selectedApp) => {
        const isCustomAppInUserApps = customApps.some(
            (app) => app.app.id === selectedApp.id
        );

        if (isCustomAppInUserApps) {
            setCustomApps((prevCustomApps) =>
                prevCustomApps.filter((app) => app.app.id !== selectedApp.id)
            );
        } else {
            const formattedApp = {
                app: {
                    appGroupId: selectedApp.appGroupId,
                    iconUrl: selectedApp.iconUrl,
                    linkPrefix: selectedApp.linkPrefix,
                    id: selectedApp.id,
                    name: selectedApp.name,
                },
                linkSufix: selectedApp.linkSufix,
            };
            setCustomApps((prevCustomApps) => [...prevCustomApps, formattedApp]);
        }

        const isAppInCommonApps = commonCustomAppIds.some(
            (app) => app.id === selectedApp.id
        );
        if (!isAppInCommonApps) {
            setCommonCustomAppIds((prevCommonApps) => [
                ...prevCommonApps,
                selectedApp,
            ]);
        }
    };

    return (
        <div>
            <div
                className="LOOK AT ME"
                style={{
                    padding: 3,
                    textAlign: "center",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ flexGrow: 1 }}>
                    <Row>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Primary"
                                type="color"
                                value={primaryColor}
                                onChange={handleColorChange(setPrimaryColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Secondary"
                                type="color"
                                value={secondaryColor}
                                onChange={handleColorChange(setSecondaryColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Highlight"
                                type="color"
                                value={highlightColor}
                                onChange={handleColorChange(setHighlightColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Bottom"
                                type="color"
                                value={bottomColor}
                                onChange={handleColorChange(setBottomColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Panel 1"
                                type="color"
                                value={namePanelColor}
                                onChange={handleColorChange(setNamePanelColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                padding: "0px",
                            }}
                            xs={6}
                            md={4}
                        >
                            <TextField
                                label="Panel 2"
                                type="color"
                                value={companyPanelColor}
                                onChange={handleColorChange(setCompanyPanelColor)}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                style={{ margin: "10px", width: "100%", height: "50px" }}
                            />
                        </Col>
                    </Row>
                    <Row className="row-center">
                        <Square elevation={3}>
                            <Color1
                                sx={{
                                    background: `linear-gradient(180deg, ${primaryColor} 0%, ${secondaryColor} 100%)`,
                                    border: `5px solid ${highlightColor}`,
                                }}
                            />
                        </Square>
                    </Row>
                    <Row height="20em" className="row-center">
                        <Rectangle sx={{ height: "6em" }} elevation={3}>
                            <Color1
                                sx={{
                                    background: `${bottomColor}`,
                                    border: `5px solid ${highlightColor}`,
                                }}
                            >
                                <Color2
                                    sx={{
                                        margin: "10px",
                                        background: `${namePanelColor}`,
                                        border: `5px solid ${highlightColor}`,
                                    }}
                                />
                                <Color2
                                    sx={{
                                        margin: "10px",
                                        background: `${companyPanelColor}`,
                                        border: `5px solid ${highlightColor}`,
                                    }}
                                />
                            </Color1>
                        </Rectangle>
                    </Row>
                    <FormControlLabel
                        sx={{ paddingTop: "1em" }}
                        control={
                            <Switch
                                checked={useWhiteText}
                                onChange={(e) => setUseWhiteText(e.target.checked)}
                                sx={{
                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                        color: "white", // Thumb color when checked
                                        "& + .MuiSwitch-track": {
                                            backgroundColor: "white", // Track color when checked
                                        },
                                    },
                                    "& .MuiSwitch-switchBase": {
                                        color: "black", // Thumb color when not checked
                                        "& + .MuiSwitch-track": {
                                            backgroundColor: "#777", // Track color when not checked
                                        },
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography color='white' variant="subtitle1" fontWeight={700}>
                                {useWhiteText ? "Use White Text" : "Use Black Text"}
                            </Typography>
                        }
                        labelPlacement="end"
                    />
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                label="Profile Name *"
                                variant="outlined"
                                name="profileName"
                                value={profileName}
                                fullWidth
                                onChange={(e) => setProfileName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                label="Profile Company *"
                                variant="outlined"
                                value={company}
                                name="Company"
                                fullWidth
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                label="Profile Title *"
                                variant="outlined"
                                name="Title"
                                value={title}
                                fullWidth
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Grid>
                        <Grid sx={{ paddingTop: 2 }} item xs={12} sm={6}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                label="Profile Email *"
                                variant="outlined"
                                name="profileEmail"
                                fullWidth
                                value={profileEmail}
                                onChange={(e) => setProfileEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: 'white',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'gray',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'lightBlue',
                                        },
                                        color: 'white', // Input text color
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white', // Label color
                                    },
                                    '& .MuiFormHelperText-root': {
                                        color: 'white', // Helper text color
                                    },
                                }}
                                customInput={TextField}
                                label="Profile Phone *"
                                format="(###) ###-####"
                                variant="outlined"
                                mask="_"
                                name="profilePhone"
                                fullWidth
                                error={phoneNumberError.length > 0}
                                helperText={phoneNumberError}
                                value={profilePhone}
                                onValueChange={(values) => handlePhoneNumberChange(values.value)}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingY: 2,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                paddingBottom: 1,
                                color: 'white' // Set text color to white
                            }}
                        >
                            Business Apps
                        </Typography>
                        <Button
                            onClick={() => setShowAllBusinessApps(!showAllBusinessApps)}
                            sx={{ color: 'white', border: '1px solid white', }} // Set button text color to white
                        >
                            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} /> {/* Set icon color to white */}
                        </Button>
                    </Box>
                    <>
                        {businessApps.length < 1 ? (
                            <Box sx={{ paddingBottom: "15px", color: 'white' }}><h5>No Business Apps Currently Active</h5> </Box>
                        ) : null}
                        {showAllBusinessApps ? (
                            <Grid className="FirstOne" container spacing={1}>
                                {allBusinessApps.map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        sx={{ textAlign: "center", marginBottom: "1em" }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: "3rem",
                                                width: "3rem",
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                                cursor: "pointer",
                                                border: commonBusinessAppIds.includes(item.id)
                                                    ? "2px solid blue"
                                                    : "none",
                                            }}
                                            alt="Business App"
                                            src={item.iconUrl}
                                            onClick={() => handleToggleBusinessApp(item)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : null}
                        {businessApps.map((selectedApp, index) => (
                            <div className="DIALOUGGGEEE" sx={{ width: 1, color: 'white' }} key={index}>
                                <img
                                    src={selectedApp.app.iconUrl}
                                    alt="App Icon"
                                    style={{ height: "3em", marginRight: "0.5rem" }}
                                />
                                <DebounceInput
                                    element={TextField}
                                    debounceTimeout={500}
                                    label={selectedApp.app.linkPrefix}
                                    sx={{
                                        width: "75%",
                                        paddingBottom: "1em",
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white', // Input text color
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Label color
                                        },
                                    }}
                                    value={selectedApp.linkSufix}
                                    onChange={(e) => {
                                        setBusinessApps((prevBusinessApps) => {
                                            const updatedBusinessApps = prevBusinessApps.map(
                                                (app) =>
                                                    app.linkSufix === selectedApp.linkSufix
                                                        ? { ...app, linkSufix: e.target.value }
                                                        : app
                                            );
                                            return updatedBusinessApps;
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingY: 2,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                paddingBottom: 1,
                                paddingRight: 4,
                                color: 'white'
                            }}
                        >
                            Payment Apps
                        </Typography>
                        <Button
                            onClick={() => setShowAllMoneyApps(!showAllMoneyApps)}
                            sx={{ color: 'white', border: '1px solid white', }}
                        >
                            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
                        </Button>
                    </Box>
                    <>
                        {paymentApps.length < 1 ? (
                            <Box sx={{ paddingBottom: "15px", color: 'white' }}><h5>No Payment Apps Currently Active</h5> </Box>
                        ) : null}
                        {showAllMoneyApps ? (
                            <Grid className="SecondOne" container spacing={1}>
                                {allPaymentApps.map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        sx={{ textAlign: "center", marginBottom: "1em" }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: "3rem",
                                                width: "3rem",
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                                cursor: "pointer",
                                                border: commonPaymentAppIds.includes(item.id)
                                                    ? "2px solid blue"
                                                    : "none",
                                            }}
                                            alt="Money App"
                                            src={item.iconUrl}
                                            onClick={() => handleTogglePaymentApp(item)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : null}
                        {paymentApps.map((selectedApp, index) => (
                            <div
                                className="DIALOUGGGEEE"
                                sx={{ width: 1, textAlign: "space-between", color: 'white' }}
                                key={index}
                            >
                                <img
                                    src={selectedApp.app.iconUrl}
                                    alt="App Icon"
                                    style={{ height: "3em", marginRight: "0.5rem" }}
                                />
                                <DebounceInput
                                    element={TextField}
                                    debounceTimeout={500}
                                    label={selectedApp.app.linkPrefix}
                                    sx={{
                                        width: "75%",
                                        paddingBottom: "1em",
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white',
                                        },
                                    }}
                                    value={selectedApp.linkSufix}
                                    onChange={(e) => {
                                        setPaymentApps((prevPaymentApps) => {
                                            const updatedPaymentApps = prevPaymentApps.map(
                                                (app) =>
                                                    app.linkSufix === selectedApp.linkSufix
                                                        ? { ...app, linkSufix: e.target.value }
                                                        : app
                                            );
                                            return updatedPaymentApps;
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingY: 2,
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 700,
                                paddingBottom: 1,
                                paddingRight: 4,
                                color: 'white'
                            }}
                        >
                            Social Apps
                        </Typography>
                        <Button
                            onClick={() => setShowAllSocialApps(!showAllSocialApps)}
                            sx={{ color: 'white', border: '1px solid white', }}
                        >
                            <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
                        </Button>
                    </Box>
                    <>
                        {socialApps.length < 1 ? (
                            <Box sx={{ paddingBottom: "15px", color: 'white' }}><h5>No Social Apps Currently Active</h5> </Box>
                        ) : null}
                        {showAllSocialApps ? (
                            <Grid className="SecondOne" container spacing={1}>
                                {allSocialApps.map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        sx={{ textAlign: "center", marginBottom: "1em" }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: "3rem",
                                                width: "3rem",
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                                cursor: "pointer",
                                                border: commonSocialAppIds.includes(item.id)
                                                    ? "2px solid blue"
                                                    : "none",
                                            }}
                                            alt="Social App"
                                            src={item.iconUrl}
                                            onClick={() => handleToggleSocialApp(item)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : null}
                        {socialApps.map((selectedApp, index) => (
                            <div
                                className="DIALOUGGGEEE"
                                sx={{ width: 1, textAlign: "space-between", color: 'white' }}
                                key={index}
                            >
                                <img
                                    src={selectedApp.app.iconUrl}
                                    alt="App Icon"
                                    style={{ height: "3em", marginRight: "0.5rem" }}
                                />
                                <DebounceInput
                                    element={TextField}
                                    debounceTimeout={500}
                                    label={selectedApp.app.linkPrefix}
                                    sx={{
                                        width: "75%",
                                        paddingBottom: "1em",
                                        '& .MuiOutlinedInput-root': {
                                            color: 'white',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'white',
                                        },
                                    }}
                                    value={selectedApp.linkSufix}
                                    onChange={(e) => {
                                        setSocialApps((prevSocialApps) => {
                                            const updatedSocialApps = prevSocialApps.map((app) =>
                                                app.linkSufix === selectedApp.linkSufix
                                                    ? { ...app, linkSufix: e.target.value }
                                                    : app
                                            );
                                            return updatedSocialApps;
                                        });
                                    }}
                                />
                            </div>
                        ))}
                    </>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingY: 2,
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 700,
                                    paddingBottom: 1,
                                    paddingRight: 4,
                                    color: 'white'
                                }}
                            >
                                Custom Apps {isPremiumUser ? null : "- Premium account required"}
                            </Typography>
                            {isPremiumUser && (
                                <Button onClick={() => setShowAllCustomApps(!showAllCustomApps)} sx={{ color: 'white', border: '1px solid white', }}>
                                    <EditIcon sx={{ fontSize: "1rem", color: 'white' }} />
                                </Button>
                            )}
                        </Box>
                        {customApps.length < 1 ? (
                            <Box sx={{ paddingBottom: "15px", color: 'white' }}><h5>No Custom Apps Currently Active</h5> </Box>
                        ) : null}
                        {showAllCustomApps && (
                            <Grid className="SecondOne" container spacing={1}>
                                {allCustomApps.map((item, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        sx={{ textAlign: "center", marginBottom: "1em" }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                height: "3rem",
                                                width: "3rem",
                                                maxHeight: { xs: 233, md: 167 },
                                                maxWidth: { xs: 350, md: 250 },
                                                cursor: "pointer",
                                                border: commonCustomAppIds.includes(item.id)
                                                    ? "2px solid blue"
                                                    : "none",
                                            }}
                                            alt="Custom App"
                                            src={item.iconUrl}
                                            onClick={() =>
                                                isPremiumUser && handleToggleCustomApp(item)
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {customApps.map((selectedApp, index) => (
                            <div
                                className="DIALOUGGGEEE"
                                sx={{ width: 1, textAlign: "space-between", color: 'white' }}
                                key={index}
                            >
                                <img
                                    src={selectedApp.app.iconUrl}
                                    alt="App Icon"
                                    style={{ height: "3em", marginRight: "0.5rem" }}
                                />
                                {isPremiumUser ? (
                                    <>
                                        <DebounceInput
                                            element={TextField}
                                            debounceTimeout={500}
                                            label={"Enter full link"}
                                            sx={{
                                                width: "55%",
                                                paddingBottom: "3px",
                                                '& .MuiOutlinedInput-root': {
                                                    color: 'white',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white',
                                                },
                                            }}
                                            value={selectedApp.linkSufix}
                                            onChange={(e) => {
                                                setCustomApps((prevCustomApps) => {
                                                    const updatedCustomApps = prevCustomApps.map(
                                                        (app) =>
                                                            app.linkSufix === selectedApp.linkSufix
                                                                ? { ...app, linkSufix: e.target.value }
                                                                : app
                                                    );
                                                    return updatedCustomApps;
                                                });
                                            }}
                                        />
                                        <DebounceInput
                                            element={TextField}
                                            debounceTimeout={500}
                                            label={"Label"}
                                            sx={{
                                                width: "20%",
                                                '& .MuiOutlinedInput-root': {
                                                    color: 'white',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'white',
                                                },
                                            }}
                                            value={selectedApp.customAppName}
                                            onChange={(e) => {
                                                setCustomApps((prevCustomApps) => {
                                                    const updatedCustomApps = prevCustomApps.map(
                                                        (app) =>
                                                            app.customAppName ===
                                                                selectedApp.customAppName
                                                                ? {
                                                                    ...app,
                                                                    customAppName: e.target.value,
                                                                }
                                                                : app
                                                    );
                                                    return updatedCustomApps;
                                                });
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Typography variant="body2" sx={{ color: 'white' }}>
                                        Premium account required to edit.
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </Box>

                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={() => setOpenSnackbar(false)}
                        sx={{ color: 'white' }} // Set Snackbar text color to white
                    >
                        <Alert
                            onClose={() => setOpenSnackbar(false)}
                            severity={
                                message?.flavor === "alert-danger" ? "error" : "success"
                            }
                            sx={{ width: "100%", color: 'white' }} // Set Alert text color to white
                        >
                            {message?.text}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            <div
                className="row-end"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                >
                    <Button
                        component={'a'}
                        variant="contained"
                        color="customColor"
                        size="large"
                        onClick={saveProfileThemeAndApps}
                    >
                        SAVE PROFILE
                    </Button>
                </Box>
                {/*<Button*/}
                {/*    size={"large"}*/}
                {/*    variant={"contained"}*/}
                {/*    type={"submit"}*/}
                {/*    onClick={() => fetchProductLink(userId)}*/}
                {/*>*/}
                {/*    Subscription Test*/}
                {/*</Button>*/}
            </div>
        </div>
    );
};

export default UserTheme;