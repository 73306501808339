// import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Grid';
// // import { colors } from '@mui/material';
// import { BlackCardAndPhone, dualCards } from '../../../../assets/images';
// const Hero = () => {
//   const theme = useTheme();
//   const isMd = useMediaQuery(theme.breakpoints.up('md'), {
//     defaultMatches: true,
//   });

//   return (
//     <Grid container spacing={4}>
//       <Grid item xs={12} md={6}>
//         <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
//           <Box marginBottom={2}>
//             <Typography
//               variant="h3"
//               color="text.primary"
//               sx={{ fontWeight: 700 }}
//             >
//               <Typography
//                 color={'primary'}
//                 component={'span'}
//                 variant={'inherit'}
//                 sx={{
//                   background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
//                   backgroundClip: 'text',
//                   WebkitTextFillColor: 'transparent',
//                 }}
//               >
//                 The NEXX{'   '}<br/>
//               </Typography>
//               Business Card
//             </Typography>
//           </Box>
//           <Box marginBottom={3}>
//             <Typography variant="h6" component="p" color="text.secondary">
//             Make lasting impressions with just a tap.<br/> Seamlessly share contact information, showcase your brand, grow your business, and connect effortlessly.
//             </Typography>
//             <Box
//               component={Button}
//               backgroundColor='#fd7f56'
//               variant="contained"
//               color="primary"
//               size="large"
//               height={54}
//               marginTop={2}
//             >
//               Take The NEXX Step
//             </Box>
//           </Box>
//         </Box>
//       </Grid>
//       <Grid
//         item
//         container
//         alignItems={'center'}
//         justifyContent={'center'}
//         xs={12}
//         md={6}
//       >
//         <Box
//           component={'img'}
//           loading="lazy"
//           height={1}
//           width={1}
//           src={BlackCardAndPhone}
//           alt="..."
//           maxWidth={600}
//         />
//       </Grid>
//     </Grid>
//   );
// };

// export default Hero;
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import SS from '../../../../assets/images/SS.png';
import Andrea from '../../../../assets/images/profiles/AndreaProfile.png';
import Kenzo from '../../../../assets/images/profiles/KenshiProfile.png';
import News from '../../../../assets/images/profiles/News.png';
import OwenProfile from '../../../../assets/images/profiles/OwenProfile.png';
import Container from '../../../../components/Container';

const images = [
  {
    group: [
      {
        cover:
        Andrea,
        coverDark:
        Andrea
      },
      {
        cover:
        News,
        coverDark:
        News
      },
      // {
      //   cover:
      //     SS,
      //   coverDark:
      //     SS
      // },
    ],
  },
  {
    group: [
      {
        cover:
          News,
        coverDark:
        News
      },
      {
        cover:
          OwenProfile,
        coverDark:
        OwenProfile
      }, 
    ],
  },
  {
    group: [
      {
        cover:
          Kenzo,
        coverDark:
        Kenzo
      },
      {
        cover:
        Kenzo,
        coverDark:
        Kenzo
      },
      {
        cover:
          Andrea,
        coverDark:
        Andrea
      },

    ],
  },
];
 
const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      sx={{
         position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: '50%' }}>
            <Typography
              variant="h2"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              Turn your connections
              <br />
              into{' '}
              <Typography
                color={'primary.attention'}
                component={'span'}
                variant={'inherit'}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    1,
                  )} 0%)`,
                }}
              >
                results.
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              NEXX cards streamline the process for lead generation.
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              marginTop={4}
            >
              <Button
                component={'a'}
                variant="contained"
                color="customColor"
                size="large"
                fullWidth={isMd ? false : true}
                href={''}
              >
                Purchase
              </Button>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            transform: 'rotate(-20deg)',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <Box
            display={'flex'}
            width={'50rem'}
            left={'50%'}
            top={0}
            position={'absolute'}
            sx={{ transform: 'translate3d(20%, -50%, 0)' }}
          >
            {images.map((item, i) => (
              <Box 
              background='transparent' // Ensures no background color
              backgroundColor= 'transparent' // Explicit background color removal
              key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                {item.group.map((g, j) => (
                  <Box
                    key={j}
                    sx={{
                      backgroundColor: 'transparent !important',
                     
                      marginTop: 2,
                    }}
                  >
                    <Box
                      component={'img'}
                      loading="lazy"
                      src={
                        theme.palette.mode === 'dark' ? g.coverDark : g.cover
                      }
                      height={1}
                      width={1}
                      maxWidth={320}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
        
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
