
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { Form } from './components';
import React, { Fragment, useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Button, Row, ButtonGroup, ListGroup, ListGroupItem, Card, CardTitle, CardHeader, CardBody, 
  Badge, Table, FormGroup, Label, Input, Alert } from "reactstrap";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Dropzone from 'react-dropzone';
import DebounceInput from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import _ from "lodash";
import Switch from "react-switch";
import { api, helpers, UserContext, constants } from "../../../src/utils";

const defaultNewUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  mobilePhoneNumber: '',
  timezone: constants.DEFAULT_TIMEZONE,
  roles: []
};

const EMPTY_ROLE = {
  typeOfUserRole: 'User'
};
const roleGroupStyles = {
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 'bold',
  textDecoration: 'underline',  
  margin: '2px 0 0 0'
};

const SignupCover = () => {
  const theme = useTheme();
  const userCtx = useContext(UserContext);
  const { id } = useParams();
  const [user, setUser] = useState(defaultNewUser);
  const [userRoles, setUserRoles] = useState(user.roles || ['User']);
  const [timezoneList, setTimezoneList] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [message, setMessage] = useState(null);
  const [roleSelectionState, setRoleSelectionState] = useState(null);
  const navigate = useNavigate;
  const isNewUser = !id || parseInt(id, 10) < 1;
  const currentUser = userCtx?.currentUser
      ? userCtx.currentUser
        : null;
  
        
  return (
    <Main>
      {console.log(id, "USER ID IN THE SIGNUP")}
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
          <Box
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            position={'relative'}
          >
            <Box
              width={1}
              order={{ xs: 2, md: 1 }}
              display={'flex'}
              alignItems={'center'}
              style={{marginRight:50}}
            >
              <Container>
                <Form id = {id}/>
              </Container>
            </Box>
            <Box
              sx={{
                flex: { xs: '0 0 100%', md: '0 0 50%' },
                position: 'relative',
                maxWidth: { xs: '100%', md: '50%' },
                order: { xs: 1, md: 2 },
                minHeight: { xs: 'auto', md: 'calc(100vh - 58px)' },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50vw' },
                  height: '100%',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      left: '0%',
                      width: 1,
                      height: 1,
                      position: { xs: 'relative', md: 'absolute' },
                      clipPath: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                      shapeOutside: {
                        xs: 'none',
                        md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: 'auto', md: 1 },
                        '& img': {
                          objectFit: 'cover',
                        },
                      }}
                    >
                      <Box
                        component={'img'}
                        loading="lazy"
                        src={
                          'https://assets.maccarianagency.com/backgrounds/img4.jpg'
                        }
                        height={{ xs: 'auto', md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          filter:
                            theme.palette.mode === 'dark'
                              ? 'brightness(0.7)'
                              : 'none',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default SignupCover;
