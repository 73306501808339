/* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import QRCodeStyling, {} from "qr-code-styling";
import {
    Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { api, helpers, UserContext, constants } from "../utils";
import { NexxLogoBlack } from "../assets/images";


// const errorCorrectionLevels = [
//   { label: "Low", value: 1},
//   { label: "Medium", value: 2},
//   { label: "High", value: 3 },
//   { label: "Ultra", value: 4 },
// ];

const dotStyleList = [
  { name: "Square", value: "square" },
  { name: "Dots", value: "dots" },
  { name: "Rounded", value: "rounded" },
  { name: "Extra Rounded", value: "extra-rounded" },
  { name: "Classy", value: "classy" },
  { name: "Classy Rounded", value: "classy-rounded" },
];
const cornerSquareStyleList = [
  { label: "None", value: "" },
  { label: "Square", value: "square" },
  { label: "Dot", value: "dot" },
  { label: "Extra Rounded", value: "extra-rounded" },
];
const cornerDotStyleList = [
  { label: "None", value: "" },
  { label: "Square", value: "square" },
  { label: "Dot", value: "dot" },
];

export default function Qr() {
  const userCtx = useContext(UserContext);
  const [user, setUser] = useState(userCtx.currentUser);
  const [dotOptionsType, setDotOptionsType] = useState("");
  const [dotOptionsColor, setDotOptionsColor] = useState("#FF0000");
  const [cornersSquareOptionsType, setCornersSquareOptionsType] = useState("");
  const [cornersSquareColor, setCornersSquareColor] = useState("#FF0000");
  const [cornersDotOptionsType, setCornersDotOptionsType] = useState("");
  const [cornersDotColor, setCornersDotOptionsColor] = useState("#FF0000");
  const [qrcWidth, setQrcWidth] = useState(250);
  const [qrcHeight, setQrcHeight] = useState(250);
  const [logoSize, setLogoSize] = useState(0.25);
  const ref = useRef(null);
  const qrCode = useRef(null);

  const getUser = async () => {
    try {
      const response = await api.fetch(`UserAdmin/User/${user.id}`);
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const getQrCode = async () => {
    try {
      const response = await api.fetch(`profile/QRCode/${user.id}`);
      if (response.data) {
        setDotOptionsType(response.data.dotType);
        setDotOptionsColor(response.data.dotColor);
        setCornersSquareOptionsType(response.data.cornerType);
        setCornersSquareColor(response.data.cornerColor);
        setCornersDotOptionsType(response.data.cornerDotType);
        setCornersDotOptionsColor(response.data.cornerDotColor);
      }
    } catch (error) {
      console.error("Error fetching QR Code:", error);
    }
  };

  useEffect(() => {
    getUser();
    getQrCode();
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl && dotOptionsType && cornersSquareOptionsType && cornersDotOptionsType) {
      qrCode.current = new QRCodeStyling({
        width: qrcWidth,
        height: qrcHeight,
        data: currentUrl,
        margin: 1,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevelId: 2,
        },
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: logoSize,
          margin: 4,
        },
        dotsOptions: {
          type: dotOptionsType,
          color: dotOptionsColor,
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        image: NexxLogoBlack,
        dotsOptionsHelper: {
          colorType: {
            single: true,
            gradient: false,
          },
          gradient: {
            linear: true,
            radial: false,
          },
        },
        cornersSquareOptions: {
          type: cornersSquareOptionsType,
          color: cornersSquareColor,
        },
        cornersSquareOptionsHelper: {
          colorType: {
            single: true,
            gradient: false,
          },
          gradient: {
            linear: true,
            radial: false,
          },
        },
        cornersDotOptions: {
          type: cornersDotOptionsType,
          color: cornersDotColor,
        },
        cornersDotOptionsHelper: {
          colorType: {
            single: true,
            gradient: false,
          },
          gradient: {
            linear: true,
            radial: false,
          },
        },
        backgroundOptionsHelper: {
          colorType: {
            single: true,
            gradient: false,
          },
          gradient: {
            linear: true,
            radial: false,
          },
        },
      });

      qrCode.current.append(ref.current);
    }
  }, [dotOptionsType, cornersSquareOptionsType, cornersDotOptionsType]);

  useEffect(() => {
    if (qrCode.current) {
      qrCode.current.update({
        data: window.location.href,
      });
    }
  }, [window.location.href]);

  useEffect(() => {
    if (qrCode.current) {
      qrCode.current.update({
        dotsOptions: {
          type: dotOptionsType,
          color: dotOptionsColor,
        },
      });
    }
  }, [dotOptionsType, dotOptionsColor]);

  useEffect(() => {
    if (qrCode.current) {
      qrCode.current.update({
        cornersSquareOptions: {
          type: cornersSquareOptionsType,
          color: cornersSquareColor,
        },
      });
    }
  }, [cornersSquareOptionsType, cornersSquareColor]);

  useEffect(() => {
    if (qrCode.current) {
      qrCode.current.update({
        cornersDotOptions: {
          type: cornersDotOptionsType,
          color: cornersDotColor,
        },
      });
    }
  }, [cornersDotOptionsType, cornersDotColor]);

  useEffect(() => {
    if (qrCode.current && qrcWidth > 150) {
      qrCode.current.update({
        width: qrcWidth,
      });
    }
  }, [qrcWidth]);

  useEffect(() => {
    if (qrCode.current && qrcHeight > 150) {
      qrCode.current.update({
        height: qrcHeight,
      });
    }
  }, [qrcHeight]);

  return (
    <div className="App">
      <div style={styles.flexContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 20,
          }}
          ref={ref}
        />
      </div>
    </div>
  );
}

const styles = {
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};