import React from 'react';
import Main from '../../layouts/Main';
import LeadList from './LeadList';

const users = [
  { id: 1, username: 'user1', email: 'user1@example.com' },
  { id: 2, username: 'user2', email: 'user2@example.com' },
  // Add more user data as needed
];

const Leads = () => {
  

  return (
    <Main style={{ width: "100vw" }}>
      <div style={{ backgroundColor: "#026670", width: "100%" }}>
        <div
          style={{
            width: "80%",
            margin: "0 auto",
            padding: "20px 0", // Adjust this as needed
          }}
        >
          <LeadList users={users} />
        </div>
      </div>
    </Main>
  );
};

export default Leads;
