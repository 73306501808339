import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useTheme,alpha } from '@mui/material/styles';
import '../../../../theme/'

const mock = [
  {
    media: {},
    title: 'Duo (2)',
    price: '10% OFF',
  },
  {
    media: {},
    title: 'Nexx Card',
    price: '$20',
  },
  {
    media: {},
    title: 'Group Buy (5+)',
    price: '30% OFF',
  },

];

const Products = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>

        <Typography
          variant="h4"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          color={'text.primary'}
          sx={{
            fontWeight: 700,
          }}
        >
          Featured products
        </Typography>
        <Typography
          variant="h6"
          align={'center'}
          color={'text.primary'}
          data-aos={'fade-up'}
        >
          Take the NEXX step in expanding your business presence.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}c
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
             <Box
    bgColor={'text.main'}
    display={'block'} 
    width={1} 
    height={1}
>
    <Box
        bgColor={`linear-gradient(120deg, transparent 100%, ${alpha(
            theme.palette.secondary.main,
            1,
        )} 0%)`}
        component={Card}
        width={1}
        height={1}
        display={'flex'}
        flexDirection={'column'}
    >
        <CardMedia
            sx={{
                position: 'relative',
                height: { xs: 240, sm: 340, md: 280 },
                overflow: 'hidden',
                padding: 3,
                paddingBottom: 0,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                backgroundColor: '#fff',
            }}
        >
            <Box
                component={'img'}
                loading="lazy"
                src={item.media}
                sx={{
                    '& img': {
                        objectFit: 'contain',
                    },
                }}
            />
            <Box
                display={'flex'}
                justifyContent={'flex-end'}
                position={'absolute'}
                top={0}
                left={0}
                right={0}
                padding={2}
                width={1}
            >
                <Box
                    component={IconButton}
                    color='white'
                    backgroundColor='#888' 
                    className='paperBackground'
                    size={'large'}
                >

                </Box>
            </Box>
        </CardMedia>
        <Box
            border={'5px solid'}
            borderColor={'border.main'} // Adjust the color as needed
            borderTop={0} // Only show the border on the sides and bottom
            borderRadius={'4px 4px 4px 4px'} // Optional: add a rounded corner effect at the bottom
        >
           <CardContent>
    <Typography
        variant={'h6'}
        align={'left'}
        sx={{ fontWeight: 700 }}
    >
        {item.title}
    </Typography>
    <Box
        display={'flex'}
        justifyContent={'flex-start'}
        marginY={1}
    >
        {/* Additional content */}
    </Box>
    <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 700 }} color={'text.primary'}>
            {item.price}
        </Typography>
        <Button
            variant={'outlined'}
            sx={{
                borderColor: 'text.primary', // Outline color
                color: 'text.primary', // Text color
                '& .MuiSvgIcon-root': {
                    fill: 'text.primary', // Icon color
                },
            }}
            startIcon={
                <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width={20}
                    height={20}
                >
                    <path
                        fillRule="evenodd"
                        d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                </Box>
            }
        >
            Add to cart
        </Button>
    </CardActions>
</CardContent>
        </Box>
    </Box>
</Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Products;
