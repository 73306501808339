/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { appIcons, profileCutoff } from '../../../../assets/images';


const Overview = () => {
  return (
    <Box
      bgcolor={'text.primary'}
      borderRadius={2}
      paddingTop={0}
      paddingBottom={{ md: 0 }}
    >
      <Grid container alignItems="flex-center" data-aos="fade-up">
        <Grid item container  xs={12} md={4}>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          md={4}
          sx={{
            marginY: 2,
            paddingX: 2,
          }}
        >
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              align={'center'}
              sx={{ fontWeight: 700, color: 'common.white' }}
            >
              Say Goodbye<br/> To Paper Cards
            </Typography>
            <Typography align={'center'} sx={{ color: 'common.white' }}>
              Purchase one NEXX business card and get free digital profile updates for life!
            </Typography>
          </Box>
        </Grid>  
      </Grid>
    </Box>
  );
};

export default Overview;
