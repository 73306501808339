
import Button from '@mui/material/Button';
import { Typography, Grid,Box  } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const FeaturedProducts = () => {
  
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  return (
    <Box position={'relative'}>
      
      <Grid container spacing={4}>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box marginBottom={2}>
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 700 }}
              >
                Beautiful data representation{' '}
                <Typography
                color={'primary.attention'}
                component={'span'}
                variant={'inherit'}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    1,
                  )} 0%)`,
                }}
              >
                  <br/> 
                built for you
                </Typography>
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="text.primary">
                Use our data dashboard to see data that will help you expand your reach, track your employee's connections, and something something something.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
               <Button
                component={'a'}
                variant="contained"
                color="customColor"
                size="large"
                fullWidth={isMd ? false : true}
                href={''}
              >
              Learn More
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'end'}
          xs={12}
          md={6}
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <Box
            component={'img'}
            loading="lazy"
            height={1}
            width={1}
            src={'https://assets.maccarianagency.com/screenshots/dashboard.png'}
            alt="..."
            boxShadow={3}
            borderRadius={2}
            maxWidth={500}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturedProducts;
