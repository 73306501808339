import React, { useContext } from 'react';

import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartDataContext } from './ChartDataContext';

// Register all elements, controllers, etc. for Chart.js
Chart.register(...registerables);

// Generate a random dataset for demonstration purposes
const randomScalingFactor = () => Math.round(Math.random() * 100);

// Rounded bar plugin
const roundedBars = {
    id: 'roundedBars',
    beforeDraw(chart) {
        const ctx = chart.ctx;
        chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
                meta.data.forEach((element) => {
                    const { x, y, base, width, options } = element;
                    const radius = options.borderRadius || 0;
                    const fullHeight = base - y;
                    const halfWidth = width / 2;

                    ctx.save();
                    ctx.fillStyle = options.backgroundColor || 'rgba(0,0,0,0.1)';
                    ctx.strokeStyle = options.borderColor || 'rgba(0,0,0,0.1)';
                    ctx.lineWidth = options.borderWidth || 1;

                    // Draw rounded bar
                    ctx.beginPath();
                    ctx.moveTo(x - halfWidth + radius, y);
                    ctx.lineTo(x + halfWidth - radius, y);
                    ctx.quadraticCurveTo(x + halfWidth, y, x + halfWidth, y + radius);
                    ctx.lineTo(x + halfWidth, base - radius);
                    ctx.quadraticCurveTo(x + halfWidth, base, x + halfWidth - radius, base);
                    ctx.lineTo(x - halfWidth + radius, base);
                    ctx.quadraticCurveTo(x - halfWidth, base, x - halfWidth, base - radius);
                    ctx.lineTo(x - halfWidth, y + radius);
                    ctx.quadraticCurveTo(x - halfWidth, y, x - halfWidth + radius, y);
                    ctx.closePath();

                    ctx.fill();
                    ctx.stroke();
                    ctx.restore();
                });
            }
        });
    }
};

// Register the plugin
Chart.register(roundedBars);

// Define the colors and fonts as constants
const colors = {
    gray: '#adb5bd',
    theme: {
        default: '#172b4d',
        primary: '#5e72e4',
        secondary: '#f4f5f7',
        info: '#11cdef',
        success: '#2dce89',
        danger: '#f5365c',
        warning: '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent'
};

const fonts = {
    base: 'Open Sans'
};

// Setup global chart options
const globalOptions = {
    responsive: true,
    maintainAspectRatio: false,
    defaultColor: colors.gray,
    defaultFontColor: colors.gray,
    defaultFontFamily: fonts.base,
    defaultFontSize: 13,
    layout: {
        padding: 0
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                padding: 16
            }
        },
        tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false
        }
    },
    scales: {
        y: {
            beginAtZero: true
        },
        x: {
            grid: {
                display: false,
                drawBorder: false
            }
        }
    }
};
const chartData = {
    labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [{
        label: 'Performance',
        data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
        backgroundColor: colors.theme.primary,
        borderColor: colors.black,
        borderWidth: 1,
        borderRadius: 5
    }]
};

// Chart examples
const ChartComponent = () => {

    return <Bar data={chartData} options={globalOptions} />;
};

export default ChartComponent;

//// Example 3
//const chartExample3 = new Chart(document.getElementById('chartExample3').getContext('2d'), {
//    type: 'line',
//    data: {
//        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//        datasets: [{
//            label: 'Performance',
//            data: [0, 20, 10, 30, 15, 40, 20, 60],
//            borderColor: colors.theme.info,
//            backgroundColor: colors.transparent,
//            borderWidth: 2,
//            tension: 0.4
//        }]
//    },
//    options: globalOptions
//});

//// Example 4
//const chartExample4 = new Chart(document.getElementById('chartExample4').getContext('2d'), {
//    type: 'line',
//    data: {
//        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//        datasets: [{
//            label: 'Performance',
//            data: [10, 18, 28, 23, 28, 40, 36, 46],
//            pointRadius: 10,
//            pointHoverRadius: 15,
//            showLine: false, // Only points are shown
//            backgroundColor: colors.theme.success,
//            borderColor: colors.transparent
//        }]
//    },
//    options: globalOptions
//});

//// Example 5
//const chartExample5 = new Chart(document.getElementById('chartExample5').getContext('2d'), {
//    type: 'doughnut',
//    data: {
//        labels: ["Danger", "Warning", "Success", "Primary", "Info"],
//        datasets: [{
//            data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
//            backgroundColor: [
//                colors.theme.danger,
//                colors.theme.warning,
//                colors.theme.success,
//                colors.theme.primary,
//                colors.theme.info
//            ],
//            label: 'Dataset 1'
//        }]
//    },
//    options: {
//        ...globalOptions,
//        cutout: '75%'
//    }
//});

//// Example 6
//const chartExample6 = new Chart(document.getElementById('chartExample6').getContext('2d'), {
//    type: 'pie',
//    data: {
//        labels: ["Danger", "Warning", "Success", "Primary", "Info"],
//        datasets: [{
//            data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
//            backgroundColor: [
//                colors.theme.danger,
//                colors.theme.warning,
//                colors.theme.success,
//                colors.theme.primary,
//                colors.theme.info
//            ],
//            label: 'Dataset 1'
//        }]
//    },
//    options: {
//        ...globalOptions,
//        cutout: '50%'
//    }
//});

//// Example 7
//const chartExample7 = new Chart(document.getElementById('chartExample7').getContext('2d'), {
//    type: 'bar',
//    data: {
//        labels: ["January", "February", "March", "April", "May", "June", "July"],
//        datasets: [
//            {
//                label: 'Dataset 1',
//                backgroundColor: colors.theme.danger,
//                data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
//                maxBarThickness: 10
//            },
//            {
//                label: 'Dataset 2',
//                backgroundColor: colors.theme.primary,
//                data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
//                maxBarThickness: 10
//            },
//            {
//                label: 'Dataset 3',
//                backgroundColor: colors.theme.success,
//                data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
//                maxBarThickness: 10
//            }
//        ]
//    },
//    options: {
//        ...globalOptions,
//        scales: {
//            x: {
//                stacked: true
//            },
//            y: {
//                stacked: true
//            }
//        }
//    }
//});



