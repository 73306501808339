import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import ProfileLayout from "../../layouts/ProfileLayout";
import Container from "../../components/Container";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import {
    Hero,
    SearchBox,
    FeaturedArticle,
    LastStories,
    CaseStudies,
    PopularArticles,
    Newsletter,
} from "./components";
import Qr from "./QrF";

const BlogReachView = () => {
    const [selectedColor, setSelectedColor] = useState("greenTheme");
    const [qrCodeText, setQrCodeText] = useState(
        ""
    );
    const [imageSrc, setImageSrc] = useState(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Facebook_icon.svg/2048px-Facebook_icon.svg.png"
    );
    const [imageSize, setImageSize] = useState(50);
    const [openModal, setOpenModal] = useState(false);
    // const [onAppChange, setOnAppChange] = useState(false);
    const [appChangeFlag, setAppChangeFlag] = useState(false);



    // const handleColorChange = (event, setState) => {
    //   clearTimeout(event.target.timerId);
    //   event.target.timerId = setTimeout(() => {
    //     setColor(event.target.value);
    //   }, 500);
    // };

    const handleFeaturedArticleRerender = () => {
        // Update any state that will trigger a rerender in FeaturedArticle
    };

    useEffect(() => {
        if (appChangeFlag) {
            console.log("Apps changed. Refreshing...");
            setAppChangeFlag(false);
        }
    }, [appChangeFlag]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        console.log("Updated color:", selectedColor);
    }, [selectedColor]);

    const theme = useTheme();
    return (
        <ProfileLayout colorInvert={true}>
            <Box className={`${selectedColor}Darkest`} position={"relative"}>
                <Hero selectedColor={selectedColor} />
                <Container
                    sx={{
                        marginTop: "-4rem",
                        position: "relative",
                        zIndex: 3,
                        paddingY: "0 !important",
                    }}
                >
                    <SearchBox
                        selectedColor={selectedColor}
                        // handleColorChange={handleColorChange}
                        // handleAppChange={handleAppChange}
                        handleFeaturedArticleRerender={handleFeaturedArticleRerender}
                    />
                </Container>
                <Container
                    paddingTop={"0 !important"}
                    className={`${selectedColor}Darkest`}
                >
                    {/* <FeaturedArticle selectedColor={selectedColor} /> */}
                </Container>
                <Container paddingTop={"0 !important"}>
                </Container>
                <Box
                    component={"svg"}
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1920 100.1"
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        transform: "translateY(50%)",
                        zIndex: 2,
                        width: 1,
                    }}
                >
                    <path
                        fill={theme.palette.background.paper}
                        d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                    ></path>
                </Box>
            </Box>
        </ProfileLayout>
    );
};

export default BlogReachView;
