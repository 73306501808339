import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
  GetStarted,
  Features,
  Benefits,
  Hero,
} from './components';

const IndexView = () => {
  const theme = useTheme();
  return (<Box><Hero />
    <Box sx={{ overflowX: 'hidden' }}>
      <Main bgcolor={'background.paper'}>
        
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0,
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container>
            <Features />
          </Container>
          <Container>
            <Benefits />
          </Container>
         
        </Box>
        <Container>
          <GetStarted />
        </Container>
      </Main>
    </Box></Box>
  );
};

export default IndexView;
