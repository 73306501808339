/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Headline = () => {
  return (
    <Box>
      <Box>
        <Typography color='text.primary' variant="h3" gutterBottom>
          Join The Many Businesses That Are
        </Typography>
        <Box display="inline">
          <Typography variant="h3" color={'text.primary'} fontWeight={700} display="inline">
            Taking The {" "}
          </Typography>
          <Typography variant="h3" color={'primary.attention'} fontWeight={700} display="inline">
             NEXX Step
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Headline;
