/* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useEffect, useRef, useState, useContext } from "react";
import _ from "lodash";
import QRCodeStyling from "qr-code-styling";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { api, helpers, UserContext, constants } from "../utils";
import { NexxLogoBlack } from "../assets/images";

const dotStyleList = [
  { name: "Square", value: "square" },
  { name: "Dots", value: "dots" },
  { name: "Rounded", value: "rounded" },
  { name: "Extra Rounded", value: "extra-rounded" },
  { name: "Classy", value: "classy" },
  { name: "Classy Rounded", value: "classy-rounded" },
];
const cornerSquareStyleList = [
  { label: "None", value: "" },
  { label: "Square", value: "square" },
  { label: "Dot", value: "dot" },
  { label: "Extra Rounded", value: "extra-rounded" },
];
const cornerDotStyleList = [
  { label: "None", value: "" },
  { label: "Square", value: "square" },
  { label: "Dot", value: "dot" },
];

export default function QrEdit() {
  const userCtx = useContext(UserContext);
  const [id, setId] = useState(0);
  const [user, setUser] = useState(userCtx.currentUser);
  const [companyId, setCompanyId] = useState(0);
  const [url, setUrl] = useState("www.nexx.co/1/example");
  const [qrcWidth, setQrcWidth] = useState("250");
  const [qrcHeight, setQrcHeight] = useState("250");
  const [dotOptionsType, setDotOptionsType] = useState("");
  const [dotOptionsColor, setDotOptionsColor] = useState("#FF0000");
  const [cornersSquareOptionsType, setCornersSquareOptionsType] = useState("");
  const [cornersSquareColor, setCornersSquareColor] = useState("#FF0000");
  const [cornersDotType, setCornersDotOptionsType] = useState("");
  const [cornersDotColor, setCornersDotOptionsColor] = useState("#FF0000");
  const [message, setMessage] = useState(null);
  const [logoSize, setLogoSize] = useState("0.25");

  const getUser = async () => {
    try {
      const response = await api.fetch(`UserAdmin/User/${user.id}`);
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const assignCompanyId = () => {
    console.log(user.companyRoles, "USER COMPANY ROLES RIGHT HERE");
    if (user.companyRoles && user.companyRoles.length > 0) {
      console.log("IT GOT INSIDE OF HERE");
      setCompanyId(user.companyRoles[0].companyId);
    }
  };

  const getQrCode = async () => {
    console.log("Fetching QR Code for companyId:", companyId);
    try {
      const response = await api.fetch(`profile/CompanyQRCode/${companyId}`);
      if (response.data) {
        setId(response.data.id);
        setDotOptionsType(response.data.dotType);
        setUrl(response.data.linkUrl);
        setDotOptionsColor(response.data.dotColor);
        setCornersSquareOptionsType(response.data.cornerType);
        setCornersSquareColor(response.data.cornerColor);
        setCornersDotOptionsType(response.data.cornerDotType);
        setCornersDotOptionsColor(response.data.cornerDotColor);
      }
    } catch (error) {
      console.error("Error fetching QR Code:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      assignCompanyId();
    }
  }, [user]);

  useEffect(() => {
    if (companyId !== 0) {
      getQrCode();
    }
  }, [companyId]);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [cornerDotStyleList]);

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    data: url,
    margin: 1,
    qrOptions: {
      typeNumber: "0",
      mode: "Byte",
      errorCorrectionLevelId: 2,
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: logoSize,
      margin: 4,
    },
    dotsOptions: {
      type: dotOptionsType,
      color: dotOptionsColor,
    },
    backgroundOptions: {
      color: "#ffffff",
    },
    image: NexxLogoBlack,
    dotsOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
      },
    },
    cornersSquareOptions: {
      type: cornersSquareOptionsType,
      color: cornersSquareColor,
    },
    cornersSquareOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
      },
    },
    cornersDotOptions: {
      type: cornersDotType,
      color: cornersDotColor,
    },
    cornersDotOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
      },
    },
    backgroundOptionsHelper: {
      colorType: {
        single: true,
        gradient: false,
      },
      gradient: {
        linear: true,
        radial: false,
      },
    },
  });

  function saveCompanyQRCode() {
    let payload = {
      id:id,
      companyId: companyId,
      dotType: dotOptionsType,
      dotColor: dotOptionsColor,
      cornerType: cornersSquareOptionsType,
      cornerColor: cornersSquareColor,
      cornerDotType: cornersDotType,
      cornerDotColor: cornersDotColor,
      errorCorrectionLevel: "medium",
      logoSize: logoSize,
    };
    api
      .post(`Profile/SaveCompanyQRCode/${user.id}`, payload)
      .then((response) => {
        if (response.data.success) {
          setMessage({ text: response.data.message, flavor: "alert-success" });
        } else {
          setMessage({ text: response.data.message, flavor: "alert-danger" });
        }
      })
      .catch((error) => {
        console.error("Error saving QR code:", error);
      });
  }

  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, [qrCode]);
  
  useEffect(() => {
    qrCode.update({
      dotOptions: {
        type: dotOptionsType,
        color: dotOptionsColor,
      },
    });
  }, [dotOptionsType, dotOptionsColor, qrCode]);
  
  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url, qrCode]);
  
  useEffect(() => {
    qrCode.update({
      cornersSquareOptions: {
        type: cornersSquareOptionsType,
        color: cornersSquareColor,
      },
    });
  }, [cornersSquareOptionsType, cornersSquareColor, qrCode]);
  
  useEffect(() => {
    qrCode.update({
      cornersDotOptions: {
        type: cornersDotType,
        color: cornersDotColor,
      },
    });
  }, [cornersDotType, cornersDotColor, qrCode]);
  
  useEffect(() => {
    if (qrcWidth > 150) {
      qrCode.update({
        width: qrcWidth,
      });
    }
  }, [qrcWidth, qrCode]);
  
  useEffect(() => {
    if (qrcHeight > 150) {
      qrCode.update({
        height: qrcHeight,
      });
    }
  }, [qrcHeight, qrCode]);

  const onDotTypeChange = (event) => {
    event.preventDefault();
    setDotOptionsType(event.target.value);
  };
  const onDotColorChange = (event) => {
    event.preventDefault();
    setDotOptionsColor(event.target.value);
  };
  const onCornerSquareTypeChange = (event) => {
    event.preventDefault();
    setCornersSquareOptionsType(event.target.value);
  };
  const onCornerSquareColorChange = (event) => {
    event.preventDefault();
    setCornersSquareColor(event.target.value);
  };
  const onCornerDotTypeChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsType(event.target.value);
  };
  const onCornerDotColorChange = (event) => {
    event.preventDefault();
    setCornersDotOptionsColor(event.target.value);
  };

  const onWidthChange = (event) => {
    event.preventDefault();
    setQrcWidth(event.target.value);
  };

  const onHeightChange = (event) => {
    event.preventDefault();
    setQrcHeight(event.target.value);
  };

  const onLogoChange = (event) => {
    event.preventDefault();
    setLogoSize(event.target.value);
  };

  return (
    <div className="App">
      <div style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }} ref={ref} />
      <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 20, paddingBottom: 20 }}>
        <FormControl sx={{ width: "60%" }}>
          <InputLabel>Dot Type</InputLabel>
          <Select value={dotOptionsType} onChange={onDotTypeChange}>
            {dotStyleList.map((style) => (
              <MenuItem key={style.name} value={style.value}>
                {style.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Dot Color"
          type="color"
          value={dotOptionsColor}
          onChange={onDotColorChange}
          sx={{ width: "30%" }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", paddingTop: 20, paddingBottom: 20 }}>
        <FormControl sx={{ width: "60%" }}>
          <InputLabel>Corner Type</InputLabel>
          <Select value={cornersSquareOptionsType} onChange={onCornerSquareTypeChange}>
            {cornerSquareStyleList.map((style) => (
              <MenuItem key={style.label} value={style.value}>
                {style.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Corner Color"
          type="color"
          value={cornersSquareColor}
          onChange={onCornerSquareColorChange}
          sx={{ width: "30%" }}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl sx={{ width: "60%" }}>
          <InputLabel>Corner Dot Type</InputLabel>
          <Select value={cornersDotType} onChange={onCornerDotTypeChange}>
            {cornerDotStyleList.map((style) => (
              <MenuItem key={style.label} value={style.value}>
                {style.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Dot Color"
          type="color"
          value={cornersDotColor}
          onChange={onCornerDotColorChange}
          sx={{ width: "30%" }}
        />
      </div>

      <div style={{ paddingTop: 20, paddingBottom: 20 }}>
        <Box
          onClick={saveCompanyQRCode}
          component={Button}
          backgroundColor="#EDEAE5"
          variant="contained"
          color="primary"
          size="large"
          height={54}
          marginTop={2}
        >
          SAVE QR Code
        </Box>
      </div>
    </div>
  );
}