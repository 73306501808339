import { yellow } from "@mui/material/colors";

export const light = {
    alternate: {
        main: '#EDEAE5',
        dark: '#151a30',
    },
    cardShadow: 'rgba(0, 0, 0, .11)',
    common: {
        black: '#000',
        white: '#fff',
        yellow:'#FCE181'
    },
    mode: 'dark',
    primary: {
        main: '#EDEAE5',
        light: '#76b9ac',
        dark: '#15322a',
        darkest: '#0e211c',
        contrastText: '#fff',
        attention: '#fd7f56',
    },
    secondary: {
        light: '#FFEA41',
        main: '#EDEAE5',
        dark: '#76BAAD',
        contrastText: 'rgba(0, 0, 0, 0.7)',
    },
    text: {
        primary: '#026670',
        secondary: '#AEB0B4',
        white: '#fff'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
        paper: '#F7F7F7',
        default: '#EDEAE5',
        level2: '#333',
        level1: '#2D3748',
    },
};

export const dark = {
  customColor: {
    main: '#FCE181', //yellow
  },

  alternate: {
    main: '#EDEAE5',
    dark: '#151a30',
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
    yellow:'#FCE181'
  },
  mode: 'dark',
  primary: {
    main: '#EDEAE5',
    light: '#76b9ac',
    dark: '#15322a',
    darkest: '#0e211c',
    contrastText: '#fff',
    attention:'#FE6D73', //Red
  },
  secondary: {
    light: '#FFEA41',
    main: '#9FEDD7', //Teal
    dark: '#76BAAD',
    contrastText: 'rgba(0, 0, 0, 0.7)',
  },
  text: {
    primary: '#026670',
    secondary: '#AEB0B4',
    white:'#fff'
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#F7F7F7',
    default: '#EDEAE5',
    level2: '#333',
    level1: '#2D3748',
  },
};
