/* eslint-disable no-unused-vars */ /* eslint-disable linebreak-style */
import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { PiAddressBookThin } from "react-icons/pi";
import { PiNotePencilThin } from "react-icons/pi";
import { default as QrEdit } from "../../QrEdit";
import CloseIcon from "@mui/icons-material/Close";

import vCard from "vcf";

const SearchBox = ({
  handleColorChange,
  handleAppChange,
  selectedColor,
  handleFeaturedArticleRerender,
}) => {
  const [leadName, setLeadName] = useState("");
  const [leadPhoneNumber, setLeadPhoneNumber] = useState("");
  const [leadEmail, setLeadEmail] = useState("");
  const [profileName, setProfileName] = useState("Profile 1");
  const [profilePhoneNumber, setProfilePhoneNumber] = useState("111-111-1111");
  const [profileEmail, setProfileEmail] = useState("profile1@gmail.com");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleShare = async () => {
    const vcard = new vCard();
    // Set properties for the vCard
    vcard.add("fn", leadName);
    vcard.add("tel", leadPhoneNumber, { type: "cell" });
    vcard.add("email", leadEmail);

    // Get vCard as a formatted string
    const vCardData = vcard.toString();

    // Create a data URI
    const encodedVCardData = encodeURIComponent(vCardData);

    // Open the default contact application
    window.location.href = `data:text/vcard;charset=utf-8,${encodedVCardData}`;

    // Close the modal after sharing
    handleCloseModal();
  };

  const handleDownload = async () => {
    const vcard = new vCard();
    // Set properties for the vCard
    vcard.add("fn", profileName);
    vcard.add("tel", profilePhoneNumber, { type: "cell" });
    vcard.add("email", profileEmail);

    // Get vCard as a formatted string
    const vCardData = vcard.toString();

    // Create a data URI
    const encodedVCardData = encodeURIComponent(vCardData);

    // Open the default contact application
    window.location.href = `data:text/vcard;charset=utf-8,${encodedVCardData}`;

    // Set the state to indicate the first card has been downloaded
    setShowModal(true);
  };

  const handleEditOpen = async () => {
    setShowEditModal(true);
  };

  return (
    <Box>
      <Box
        className={`${selectedColor}Med ${selectedColor}Border`}
        padding={2}
        width={1}
        component={Card}
        boxShadow={4}
        marginBottom={3}
      >
        <Box display="flex" alignItems={"center"}>
          <Box marginTop={"0 !important"}></Box>
          <Box
            width={1}
            marginRight={1}
            sx={{ textAlign: "center", padding: 0, margin: 0 }}
          >
            <Button
              // className={`${selectedColor}Med`}
              sx={{ padding: 0 }}
              // variant='contained'
              onClick={handleEditOpen}
            >
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 700, textAlign: "center" }}
              >
                Nevin Anuran
              </Typography>
            </Button>
          </Box>
          <Box marginTop={"0 !important"}></Box>
        </Box>
      </Box>
      <Box
        className={`${selectedColor}Dark ${selectedColor}Border`}
        padding={2}
        width={1}
        component={Card}
        boxShadow={4}
        marginBottom={3}
      >
        <Box display="flex" alignItems={"center"}>
          <Box width={1} marginRight={1}>
            <Typography
              variant="h5"
              color="text.primary"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              CEO - NEXX Business Cards
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Modal
          open={showEditModal}
          onClose={handleCloseEditModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%", // Set the width to 90% of the viewport width
            maxWidth: "650px", // Set the maximum width to 650px
            // maxHeight: '80vh',
            // overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              background: "#121212",
              width: "90%",
              border: "2px solid #000",
              p: 2,
              maxHeight: "80vh", // Set maximum height
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            {console.log(handleAppChange, "HANDLLEEE")}
            <Typography variant="h6" gutterBottom>
              Edit Your Profile
            </Typography>
            
          </Box>
        </Modal>
      </Box>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className={`${selectedColor}Med ${selectedColor}Border`}
          sx={{
            position: "absolute",
            width: "70%",
            border: "2px solid #000",
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Share Your Information Back!
          </Typography>
          <form noValidate autoComplete="off">
            <TextField
              label="First And Last Name"
              value={leadName}
              onChange={(e) => setLeadName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone Number"
              value={leadPhoneNumber}
              onChange={(e) => setLeadPhoneNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={leadEmail}
              onChange={(e) => setLeadEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              className={`${selectedColor}Light ${selectedColor}Border`}
              variant="contained"
              onClick={handleShare}
            >
              Share
            </Button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default SearchBox;
