import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";

export default function MycoDropzone({ maxFileLimit = 1, onDropCallback }) {
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles = acceptedFiles && acceptedFiles.length ? acceptedFiles : [];
    acceptedFiles.length = maxFileLimit;
    setFiles(acceptedFiles);
    onDropCallback(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className="greenThemeBorder"
      style={{ borderRadius: 8 }}
    >
      <input {...getInputProps()} />
      <div
        style={{
          minHeightheight: 100,
          padding: "10%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          display: "flex",
        }}
      >
        {isDragActive ? (
          <span>Drop Files Here</span>
        ) : (
          <span>Upload Profile Picture</span>
        )}
      </div>
      <div
        style={{
          minHeightheight: 50,
          backgroundColor: "lightgrey",
          marginTop: "5%",
          padding: "2%",
          display: files && files.length > 0 ? undefined : "none",
        }}
      >
        {files &&
          files.length > 0 &&
          files.map((f, idx) => {
            console.log("uploaded image ", f);
            return (
              <div key={`${f.name}-${idx}`} className="font-semibold">
                <p>
                  <img
                    src={URL.createObjectURL(f)}
                    alt={f.name}
                    height={"20%"}
                    width={"20%"}
                  />
                </p>
                <p style={{ color: "black" }}>{f.name}</p>
              </div>
            );
          })}
        {files && files.length > 0 && (
          <p>
            <Button
              className="projectPrimary greenThemeBorder"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFiles([]);
                onDropCallback([]);
              }}
            >
              clear
            </Button>
          </p>
        )}
      </div>
    </div>
  );
}
