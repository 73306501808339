/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "../../../assets/css/argon-dashboard-pro-react.css"
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader.js";

import ChartComponent, {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.js";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  //const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  //const toggleNavs = (e, index) => {
  //  e.preventDefault();
  //  setActiveNav(index);
  //  setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  //};
  //if (window.Chart) {
  //  parseOptions(Chart, chartOptions());
  //}
  return (
    <>
      <CardsHeader name="Default" />
      <Container className="mt--6" fluid>
      
        
        <Row>
          {/*<Col xl="5">*/}
          {/*  <Card>*/}
          {/*    <CardHeader>*/}
          {/*      <h5 className="h3 mb-0">Activity feed</h5>*/}
          {/*    </CardHeader>*/}
          {/*    <CardHeader className="d-flex align-items-center">*/}
          {/*      <div className="d-flex align-items-center">*/}
          {/*        <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*          <img*/}
          {/*            alt="..."*/}
          {/*            className="avatar"*/}
          {/*                                src={require("../../../assets/img/theme/team-1.jpg")}*/}
          {/*          />*/}
          {/*        </a>*/}
          {/*        <div className="mx-3">*/}
          {/*          <a*/}
          {/*            className="text-dark font-weight-600 text-sm"*/}
          {/*            href="#pablo"*/}
          {/*            onClick={(e) => e.preventDefault()}*/}
          {/*          >*/}
          {/*            John Snow*/}
          {/*          </a>*/}
          {/*          <small className="d-block text-muted">3 days ago</small>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="text-right ml-auto">*/}
          {/*        <Button*/}
          {/*          className="btn-icon"*/}
          {/*          color="primary"*/}
          {/*          size="sm"*/}
          {/*          type="button"*/}
          {/*        >*/}
          {/*          <span className="btn-inner--icon mr-1">*/}
          {/*            <i className="ni ni-fat-add" />*/}
          {/*          </span>*/}
          {/*          <span className="btn-inner--text">Follow</span>*/}
          {/*        </Button>*/}
          {/*      </div>*/}
          {/*    </CardHeader>*/}
          {/*    <CardBody>*/}
          {/*      <p className="mb-4">*/}
          {/*        Personal profiles are the perfect way for you to grab their*/}
          {/*        attention and persuade recruiters to continue reading your CV*/}
          {/*        because you’re telling them from the off exactly why they*/}
          {/*        should hire you.*/}
          {/*      </p>*/}
          {/*      <img*/}
          {/*        alt="..."*/}
          {/*        className="img-fluid rounded"*/}
          {/*                        src={require("../../../assets/img/theme/img-1-1000x600.jpg")}*/}
          {/*      />*/}
          {/*      <Row className="align-items-center my-3 pb-3 border-bottom">*/}
          {/*        <Col sm="6">*/}
          {/*          <div className="icon-actions">*/}
          {/*            <a*/}
          {/*              className="like active"*/}
          {/*              href="#pablo"*/}
          {/*              onClick={(e) => e.preventDefault()}*/}
          {/*            >*/}
          {/*              <i className="ni ni-like-2" />*/}
          {/*              <span className="text-muted">150</span>*/}
          {/*            </a>*/}
          {/*            <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*              <i className="ni ni-chat-round" />*/}
          {/*              <span className="text-muted">36</span>*/}
          {/*            </a>*/}
          {/*            <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*              <i className="ni ni-curved-next" />*/}
          {/*              <span className="text-muted">12</span>*/}
          {/*            </a>*/}
          {/*          </div>*/}
          {/*        </Col>*/}
          {/*        <Col className="d-none d-sm-block" sm="6">*/}
          {/*          <div className="d-flex align-items-center justify-content-sm-end">*/}
          {/*            <div className="avatar-group">*/}
          {/*              <a*/}
          {/*                className="avatar avatar-xs rounded-circle"*/}
          {/*                href="#pablo"*/}
          {/*                id="tooltip36177092"*/}
          {/*                onClick={(e) => e.preventDefault()}*/}
          {/*              >*/}
          {/*                <img*/}
          {/*                  alt="..."*/}
          {/*                                            src={require("../../../assets/img/theme/team-1.jpg")}*/}
          {/*                />*/}
          {/*              </a>*/}
          {/*              <UncontrolledTooltip delay={0} target="tooltip36177092">*/}
          {/*                Jessica Rowland*/}
          {/*              </UncontrolledTooltip>*/}
          {/*              <a*/}
          {/*                className="avatar avatar-xs rounded-circle"*/}
          {/*                href="#pablo"*/}
          {/*                id="tooltip857639221"*/}
          {/*                onClick={(e) => e.preventDefault()}*/}
          {/*              >*/}
          {/*                <img*/}
          {/*                  alt="..."*/}
          {/*                                            className="rounded-circle"*/}
          {/*                                            width="150px"*/}
          {/*                                            src={require("../../../assets/img/theme/team-2.jpg")}*/}
          {/*                />*/}
          {/*              </a>*/}
          {/*              <UncontrolledTooltip*/}
          {/*                delay={0}*/}
          {/*                target="tooltip857639221"*/}
          {/*              >*/}
          {/*                Audrey Love*/}
          {/*              </UncontrolledTooltip>*/}
          {/*              <a*/}
          {/*                className="avatar avatar-xs rounded-circle"*/}
          {/*                href="#pablo"*/}
          {/*                id="tooltip260223080"*/}
          {/*                onClick={(e) => e.preventDefault()}*/}
          {/*              >*/}
          {/*                <img*/}
          {/*                  alt="..."*/}
          {/*                  className="rounded-circle"*/}
          {/*                                            src={require("../../../assets/img/theme/team-3.jpg")}*/}
          {/*                />*/}
          {/*              </a>*/}
          {/*              <UncontrolledTooltip*/}
          {/*                delay={0}*/}
          {/*                target="tooltip260223080"*/}
          {/*              >*/}
          {/*                Michael Lewis*/}
          {/*              </UncontrolledTooltip>*/}
          {/*            </div>*/}
          {/*            <small className="pl-2 font-weight-bold">*/}
          {/*              and 30+ more*/}
          {/*            </small>*/}
          {/*          </div>*/}
          {/*        </Col>*/}
          {/*      </Row>*/}

          {/*      <div className="mb-1">*/}
          {/*        <Media className="media-comment">*/}
          {/*          <img*/}
          {/*            alt="..."*/}
          {/*            className="avatar avatar-lg media-comment-avatar rounded-circle"*/}
          {/*                                src={require("../../../assets/img/theme/team-1.jpg")}*/}
          {/*          />*/}
          {/*          <Media>*/}
          {/*            <div className="media-comment-text">*/}
          {/*              <h6 className="h5 mt-0">Michael Lewis</h6>*/}
          {/*              <p className="text-sm lh-160">*/}
          {/*                Cras sit amet nibh libero nulla vel metus scelerisque*/}
          {/*                ante sollicitudin. Cras purus odio vestibulum in*/}
          {/*                vulputate viverra turpis.*/}
          {/*              </p>*/}
          {/*              <div className="icon-actions">*/}
          {/*                <a*/}
          {/*                  className="like active"*/}
          {/*                  href="#pablo"*/}
          {/*                  onClick={(e) => e.preventDefault()}*/}
          {/*                >*/}
          {/*                  <i className="ni ni-like-2" />*/}
          {/*                  <span className="text-muted">3 likes</span>*/}
          {/*                </a>*/}
          {/*                <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*                  <i className="ni ni-curved-next" />*/}
          {/*                  <span className="text-muted">2 shares</span>*/}
          {/*                </a>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </Media>*/}
          {/*        </Media>*/}
          {/*        <Media className="media-comment">*/}
          {/*          <img*/}
          {/*            alt="..."*/}
          {/*                                className="avatar avatar-lg media-comment-avatar rounded-circle"*/}
          {/*                                width="150px"*/}
          {/*                                src={require("../../../assets/img/theme/team-2.jpg")}*/}
          {/*          />*/}
          {/*          <Media>*/}
          {/*            <div className="media-comment-text">*/}
          {/*              <h6 className="h5 mt-0">Jessica Stones</h6>*/}
          {/*              <p className="text-sm lh-160">*/}
          {/*                Cras sit amet nibh libero, in gravida nulla. Nulla vel*/}
          {/*                metus scelerisque ante sollicitudin. Cras purus odio,*/}
          {/*                vestibulum in vulputate at, tempus viverra turpis.*/}
          {/*              </p>*/}
          {/*              <div className="icon-actions">*/}
          {/*                <a*/}
          {/*                  className="like active"*/}
          {/*                  href="#pablo"*/}
          {/*                  onClick={(e) => e.preventDefault()}*/}
          {/*                >*/}
          {/*                  <i className="ni ni-like-2" />*/}
          {/*                  <span className="text-muted">10 likes</span>*/}
          {/*                </a>*/}
          {/*                <a href="#pablo" onClick={(e) => e.preventDefault()}>*/}
          {/*                  <i className="ni ni-curved-next" />*/}
          {/*                  <span className="text-muted">1 share</span>*/}
          {/*                </a>*/}
          {/*              </div>*/}
          {/*            </div>*/}
          {/*          </Media>*/}
          {/*        </Media>*/}
          {/*        <hr />*/}
          {/*        <Media className="align-items-center">*/}
          {/*          <img*/}
          {/*            alt="..."*/}
          {/*            className="avatar avatar-lg rounded-circle mr-4"*/}
          {/*                                src={require("../../../assets/img/theme/team-3.jpg")}*/}
          {/*          />*/}
          {/*          <Media body>*/}
          {/*            <Form>*/}
          {/*              <Input*/}
          {/*                placeholder="Write your comment"*/}
          {/*                rows="1"*/}
          {/*                type="textarea"*/}
          {/*              />*/}
          {/*            </Form>*/}
          {/*          </Media>*/}
          {/*        </Media>*/}
          {/*      </div>*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}
          <Col xl="7">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Groups</h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Group
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          Group Leader
                        </th>
                        <th scope="col">Users</th>
                        <th className="sort" data-sort="completion" scope="col">
                          Leads
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/bootstrap.jpg")}
                              />
                            </a>
                            <Media>
                              <span className="name mb-0 text-sm">
                               Group 1
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget">Mark Johnson</td>
                        <td>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip792717700"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-1.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip792717700"
                            >
                              Ryan Tompson
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip654289872"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                                              alt="..."
                                                              width="150px"
                                                              src={require("../../../assets/img/theme/team-2.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip654289872"
                            >
                              Romina Hadid
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip409131762"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-3.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip409131762"
                            >
                              Alexander Smith
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip50788433"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-4.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip50788433"
                            >
                              Jessica Doe
                            </UncontrolledTooltip>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="completion mr-2">60</span>
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/angular.jpg")}
                              />
                            </a>
                            <Media>
                              <span className="name mb-0 text-sm">
                                Group 2
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget">Jesse Bessoir</td>
                       
                        <td>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip545726644"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-1.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip545726644"
                            >
                              Ryan Tompson
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip823332447"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                                              alt="..."
                                                              width="150px"
                                                              src={require("../../../assets/img/theme/team-2.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip823332447"
                            >
                              Romina Hadid
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip354076640"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-3.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip354076640"
                            >
                              Alexander Smith
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip625572621"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-4.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip625572621"
                            >
                              Jessica Doe
                            </UncontrolledTooltip>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="completion mr-2">100</span>
                        
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/sketch.jpg")}
                              />
                            </a>
                            <Media>
                              <span className="name mb-0 text-sm">
                                Group 3
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget">Tina Jones</td>
                        
                        <td>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip927457712"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-1.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip927457712"
                            >
                              Ryan Tompson
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip959509788"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                                              alt="..."
                                                              width="150px"
                                                              src={require("../../../assets/img/theme/team-2.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip959509788"
                            >
                              Romina Hadid
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip239649821"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-3.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip239649821"
                            >
                              Alexander Smith
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip908443321"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-4.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip908443321"
                            >
                              Jessica Doe
                            </UncontrolledTooltip>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="completion mr-2">72</span>
                         
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/react.jpg")}
                              />
                            </a>
                            <Media>
                              <span className="name mb-0 text-sm">
                                Group 4
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td className="budget">Shen Zhu</td>
                    
                        <td>
                          <div className="avatar-group">
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip817843622"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-1.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip817843622"
                            >
                              Ryan Tompson
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip885824111"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                                              alt="..."
                                                              width="150px"
                                                              src={require("../../../assets/img/theme/team-2.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip885824111"
                            >
                              Romina Hadid
                            </UncontrolledTooltip>
                            <a
                              className="avatar avatar-sm rounded-circle"
                              href="#pablo"
                              id="tooltip426851535"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                                              src={require("../../../assets/img/theme/team-3.jpg")}
                              />
                            </a>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip426851535"
                            >
                              Alexander Smith
                            </UncontrolledTooltip>z
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="completion mr-2">90</span>
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      
                     
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
            <div className="card-deck">
              {/*<Card className="bg-gradient-default">*/}
              {/*  <CardBody>*/}
              {/*    <div className="mb-2">*/}
              {/*      <sup className="text-white">$</sup>{" "}*/}
              {/*      <span className="h2 text-white">3,300</span>*/}
              {/*      <div className="text-light mt-2 text-sm">*/}
              {/*        Your current balance*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <span className="text-success font-weight-600">*/}
              {/*          + 15%*/}
              {/*        </span>{" "}*/}
              {/*        <span className="text-light">($250)</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <Button*/}
              {/*      block*/}
              {/*      className="btn-neutral"*/}
              {/*      color="default"*/}
              {/*      size="sm"*/}
              {/*    >*/}
              {/*      Add credit*/}
              {/*    </Button>*/}
              {/*  </CardBody>*/}
              {/*  <CardBody>*/}
              {/*    <Row>*/}
              {/*      <div className="col">*/}
              {/*        <small className="text-light">Orders: 60%</small>*/}
              {/*        <Progress*/}
              {/*          className="progress-xs my-2"*/}
              {/*          max="100"*/}
              {/*          value="60"*/}
              {/*          color="success"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <div className="col">*/}
              {/*        <small className="text-light">Sales: 40%</small>*/}
              {/*        <Progress*/}
              {/*          className="progress-xs my-2"*/}
              {/*          max="100"*/}
              {/*          value="40"*/}
              {/*          color="warning"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </Row>*/}
              {/*  </CardBody>*/}
              {/*</Card>*/}
              {/*<Card className="bg-gradient-danger">*/}
              {/*  <CardBody>*/}
              {/*    <Row className="justify-content-between align-items-center">*/}
              {/*      <div className="col">*/}
              {/*        <img*/}
              {/*          alt="..."*/}
              {/*                                src={require("../../../assets/img/icons/cards/bitcoin.png")}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*      <Col className="col-auto">*/}
              {/*        <Badge className="badge-lg" color="success">*/}
              {/*          Active*/}
              {/*        </Badge>*/}
              {/*      </Col>*/}
              {/*    </Row>*/}
              {/*    <div className="my-4">*/}
              {/*      <span className="h6 surtitle text-light">Username</span>*/}
              {/*      <div className="h1 text-white">@johnsnow</div>*/}
              {/*    </div>*/}
              {/*    <Row>*/}
              {/*      <div className="col">*/}
              {/*        <span className="h6 surtitle text-light">Name</span>*/}
              {/*        <span className="d-block h3 text-white">John Snow</span>*/}
              {/*      </div>*/}
              {/*    </Row>*/}
              {/*  </CardBody>*/}
              {/*</Card>*/}
            </div>
          </Col>
        </Row>
              <Row>
                  <Col xl="8">
                      <Card className="bg-default">
                          <div className="chart"><ChartComponent /></div>

                          {/*<CardHeader className="bg-transparent">*/}
                          {/*  <Row className="align-items-center">*/}
                          {/*    <div className="col">*/}
                          {/*      <h6 className="text-light text-uppercase ls-1 mb-1">*/}
                          {/*        Overview*/}
                          {/*      </h6>*/}
                          {/*      <h5 className="h3 text-white mb-0">Sales value</h5>*/}
                          {/*    </div>*/}
                          {/*    <div className="col">*/}
                          {/*      <Nav className="justify-content-end" pills>*/}
                          {/*        <NavItem className="mr-2 mr-md-0">*/}
                          {/*          <NavLink*/}
                          {/*            className={classnames("py-2 px-3", {*/}
                          {/*              active: activeNav === 1,*/}
                          {/*            })}*/}
                          {/*            href="#pablo"*/}
                          {/*            onClick={(e) => toggleNavs(e, 1)}*/}
                          {/*          >*/}
                          {/*            <span className="d-none d-md-block">Month</span>*/}
                          {/*            <span className="d-md-none">M</span>*/}
                          {/*          </NavLink>*/}
                          {/*        </NavItem>*/}
                          {/*        <NavItem>*/}
                          {/*          <NavLink*/}
                          {/*            className={classnames("py-2 px-3", {*/}
                          {/*              active: activeNav === 2,*/}
                          {/*            })}*/}
                          {/*            data-toggle="tab"*/}
                          {/*            href="#pablo"*/}
                          {/*            onClick={(e) => toggleNavs(e, 2)}*/}
                          {/*          >*/}
                          {/*            <span className="d-none d-md-block">Week</span>*/}
                          {/*            <span className="d-md-none">W</span>*/}
                          {/*          </NavLink>*/}
                          {/*        </NavItem>*/}
                          {/*      </Nav>*/}
                          {/*    </div>*/}
                          {/*  </Row>*/}
                          {/*</CardHeader>*/}
                          <CardBody>
                              {/*<div className="chart">*/}
                              {/*  <Line*/}
                              {/*    data={chartExample1[chartExample1Data]}*/}
                              {/*    options={chartExample1.options}*/}
                              {/*    id="chart-sales-dark"*/}
                              {/*    className="chart-canvas"*/}
                              {/*  />*/}
                              {/*</div>*/}
                          </CardBody>
                      </Card>
                  </Col>

              </Row>
      </Container>
    </>
  );
}

export default Dashboard;
