import React, { useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import _ from "lodash";
import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import Main from "../../layouts/Main";
import Container from "../../components/Container";
import { Button, Form } from "reactstrap";
// import { Form } from './components';
import { api, helpers, UserContext, storage } from "../../utils";
import { SignInPhoto } from "../../assets/images";
import { CircularProgress, Grid, useMediaQuery } from "@mui/material";

const SigninCover = (props) => {
  const forgotPasswordPath = "/forgot_password";
  const theme = useTheme();
  const userCtx = useContext(UserContext);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  function handleSubmit(e) {
    e.preventDefault();
    if(isLoading) return;
    setIsLoading(true);
    api
      .post("Public/Authenticate", {
        Username: username,
        Password: password,
      })
        .then((response) => {
            console.log("response here ", response);
            if (response.data.success) {
                userCtx.signIn(response.data.user, response.data.user.token);
                setTimeout(() => {
                    navigate("/");
                }, 1000);
            } else {
                // Set a specific error message for incorrect username/password combination
                setMessage({
                    flavor: "alert-danger",
                    text: "Incorrect password and email combination, please try again or select 'Forgot Password' below."
                });
            }
        })
      .catch(helpers.catchHandler)
      .finally(() => setIsLoading(false));
  }

  const canSubmit =
    username && username.length > 2 && password && password.length > 7;
  return (
    <Main>
      <Box
        sx={{
          width: 1,
          height: 1,
          overflow: "hidden",
        }}
      >
        <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            position={"relative"}
          >
            <Box
              width={1}
              order={{ xs: 2, md: 1 }}
              display={"flex"}
              alignItems={"center"}
            >
                          <Container>
                              {message && (
                                  <div className={`alert ${message.flavor}`}>
                                      {message.text}
                                  </div>
                              )}
                <Form id="sign_in_form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      autoFocus
                      type="text"
                      style={{ marginTop: "0" }}
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      style={{ marginTop: "0" }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter password"
                    />
                  </div>
                  {/*<div style={{ float: "left" }}>*/}
                    <Grid container display={'flex'} rowSpacing={2}
                          sx={{justifyContent: 'space-between'}}>
                      <Grid item xs={12} md={3}>
                    <Button
                      type="submit"
                      disabled={!canSubmit || isLoading}
                      className={"w-100"}
                                              style={{ backgroundColor: canSubmit && !isLoading ? "#026670" : 'gray'}}
                    >
                      {isLoading ? <CircularProgress style={{'color': 'white'}}/> : 'Sign In'}

                    </Button>
                      </Grid>
                      <Grid item md={5}
                            xs={12}>
                        <Link
                          xs={5}
                          className={classnames({ "text-center w-100": isMobile, "w-100 text-right": !isMobile })}
                          style={{fontSize: 18}}
                          state={{ email: username }}
                          to={{
                            pathname: forgotPasswordPath,
                            push: true,
                          }}
                        >
                          Forgot Password
                        </Link>
                      </Grid>
                    </Grid>
                  {/*</div>*/}
                  {/*<div style={{ float: "right" }} className="text-left">*/}
                  {/*  <Link*/}
                  {/*    state={{ email: username }}*/}
                  {/*    to={{*/}
                  {/*      pathname: forgotPasswordPath,*/}
                  {/*      push: true,*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    Forgot Password*/}
                  {/*  </Link>*/}
                  {/*</div>*/}
                </Form>
                {/* <Form /> */}
              </Container>
            </Box>
            <Box
              sx={{
                flex: { xs: "0 0 100%", md: "0 0 50%" },
                position: "relative",
                maxWidth: { xs: "100%", md: "50%" },
                order: { xs: 1, md: 2 },
                minHeight: { xs: "auto", md: "calc(100vh - 58px)" },
              }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: "50vw" },
                  height: "100%",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "hidden",
                      left: "0%",
                      width: 1,
                      height: 1,
                      position: { xs: "relative", md: "absolute" },
                      clipPath: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                      shapeOutside: {
                        xs: "none",
                        md: "polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: { xs: "auto", md: 1 },
                        "& img": {
                          objectFit: "cover",
                        },
                      }}
                    >
                      <Box
                        component={"img"}
                        loading="lazy"
                        src={SignInPhoto}
                        height={{ xs: "auto", md: 1 }}
                        maxHeight={{ xs: 300, md: 1 }}
                        width={1}
                        maxWidth={1}
                        sx={{
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.7)"
                              : "none",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Main>
  );
};

export default SigninCover;
